/*
Purpose: list details of student with use datatable
Developer: BY KTH
Date:22/08/2023 
Project: TCMS
*/

import { useState, useCallback, useEffect, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Row, Col, Tab, Nav, Card, Form, Modal } from "react-bootstrap";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Button as Buttonmui } from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import AddIcon from "@mui/icons-material/Add";

import { useFormik } from "formik";
import * as yup from "yup";

import axios from "axios";
import { security_key, BASE_URL } from "../global";
import Moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";

const Student = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const formRef = useRef(null);
  const formRefSP = useRef(null);
  const formRefCC = useRef(null);
  //stripe payment start
  const [isSubmittingSPayment, setIsSubmittingSPayment] = useState(false);
  const [showPopupGenerateSPayment, setShowPopupGenerateSPayment] =
    useState(false);
  const [showPopupOfflinePayment, setShowPopupOfflinePayment] = useState(false);
  const [DataSP, setDataSP] = useState([]);
  //stripe payment start
  const [isSubmittingPayment, setIsSubmittingPayment] = useState(false);
  const [showPopupGeneratePayment, setShowPopupGeneratePayment] =
    useState(false);
  const [DataGP, setDataGP] = useState([]);
  const [isActive, setIsActive] = useState(true);

  //Card change start
  const [isSubmittingCC, setIsSubmittingCC] = useState(false);
  const [showPopupGenerateCC, setShowPopupGenerateCC] = useState(false);
  const [DataCC, setDataCC] = useState([]);

  const handleClick = (event) => {
    setIsActive((isActive) => !isActive);
  };

  const handleStudentViewClick = async (e) => {
    if (parseInt(e.visibility) == 1) {
      navigate(`/view-student`, {
        state: { pk_student_key: `${e.pk_student_key}` },
      });
    } else {
      toast.error("Sorry! Allow only active student.", {
        autoClose: 3000, // 3 seconds in this example
      });
    }
  };
  const handleStudentEditClick = async (e) => {
    if (parseInt(e.visibility) == 1) {
      navigate(`/student-profile`, {
        state: { pk_student_key: `${e.pk_student_key}` },
      });
    } else {
      toast.error("Sorry! Allow only active student.", {
        autoClose: 3000, // 3 seconds in this example
      });
    }
  };

  const [showDelete, setDeleteShow] = useState(false);
  const [data_delete, setDeleteLabelData] = useState([]);
  const [isUserDeleteSubmitting, setIsUserDeleteSubmitting] = useState(false);

  const handleDeleteClick = (row) => {
    const updatedData = { ...row };
    setDeleteLabelData(updatedData);

    setDeleteShow(true);
  };

  const [showVisible, setVisibleShow] = useState(false);
  const [data_visible, setVisibleLabelData] = useState([]);
  const [isVisibleSubmitting, setIsVisibleSubmitting] = useState(false);

  const handleVisibleClick = (row) => {
    const updatedData = { ...row };
    setVisibleLabelData(updatedData);

    setVisibleShow(true);
  };

  function onSubmitDelete() {
    setIsUserDeleteSubmitting(true);

    const responsedelete = axios
      .post(BASE_URL + "API/api_index.php", {
        security_key: security_key,
        updated_by: sessionStorage.getItem("key"),
        mode: "deleteStudent",
        pk_student_key: data_delete.pk_student_key,
      })
      .then((responsedelete) => {
        if (responsedelete.data.data == 1) {
          setDeleteShow(false);
          // Handle the successful response here
          toast.success("Success! Student has been inactivated.", {
            autoClose: 3000, // 3 seconds in this example
          });

          fetchData();

          setIsUserDeleteSubmitting(false);
        } else {
          toast.error(
            "Failure! Unable to Student has been inactive. Try again later",
            {
              autoClose: 3000, // 3 seconds in this example
            }
          );
          setIsUserDeleteSubmitting(false);
        }
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        toast.error(
          "Failure! Unable to Student has been inactive. Try again later",
          {
            autoClose: 3000, // 3 seconds in this example
          }
        );
        setIsUserDeleteSubmitting(false);
      });
  }

  function onSubmitVisible() {
    setIsVisibleSubmitting(true);

    const responseVisible = axios
      .post(BASE_URL + "API/api_index.php", {
        security_key: security_key,
        updated_by: sessionStorage.getItem("key"),
        mode: "VisibleStudent",
        pk_student_key: data_visible.pk_student_key,
      })
      .then((responseVisible) => {
        if (responseVisible.data.data == 1) {
          setVisibleShow(false);
          // Handle the successful response here
          toast.success("Success! Student has been activated.", {
            autoClose: 3000, // 3 seconds in this example
          });

          fetchData();

          setIsVisibleSubmitting(false);
        } else {
          toast.error(
            "Failure! Unable to Student has been active. Try again later",
            {
              autoClose: 3000, // 3 seconds in this example
            }
          );
          setIsVisibleSubmitting(false);
        }
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        toast.error(
          "Failure! Unable to Student has been active. Try again later",
          {
            autoClose: 3000, // 3 seconds in this example
          }
        );
        setIsVisibleSubmitting(false);
      });
  }

  //Cancel subscription

  const [showCancelPayment, setCancelPayment] = useState(false);
  const [data_cancel, setCancelLabelData] = useState([]);
  const [isUserCancelSubmitting, setIsUserCancelSubmitting] = useState(false);

  const handleCancelClick = (row) => {
    const updatedData = { ...row };
    setCancelLabelData(updatedData);

    setCancelPayment(true);
  };

  function onSubmitCancelPayment() {
    setIsUserCancelSubmitting(true);

    const responseCancel = axios
      .post(BASE_URL + "API/api_index.php", {
        security_key: security_key,
        fk_cent_key: sessionStorage.getItem("key"),
        mode: "cancelOnlinePaymentType",
        pk_student_kay: data_cancel.pk_student_key,
        //pk_ss_key: data_cancel.stripe_scbscrip_key
      })
      .then((responseCancel) => {
        if (responseCancel.data.data == 1) {
          setCancelPayment(false);
          // Handle the successful response here
          toast.success("Success! Online payment canceled successfully!", {
            autoClose: 3000, // 3 seconds in this example
          });

          fetchData();

          setIsUserCancelSubmitting(false);
        } else {
          toast.error(
            "Failure! Unable to online payment cancel. Try again later",
            {
              autoClose: 3000, // 3 seconds in this example
            }
          );
          setIsUserCancelSubmitting(false);
        }
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        toast.error(
          "Failure! Unable to online payment cancel. Try again later",
          {
            autoClose: 3000, // 3 seconds in this example
          }
        );
        setIsUserCancelSubmitting(false);
      });
  }

  //Api call start
  const [data, setItems] = useState([]);
  const [dataDeactive, setDeactiveItems] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.post(BASE_URL + "API/api_index.php", {
        security_key: security_key,
        mode: "getAllStudentWithCard",
        fk_cent_key: sessionStorage.getItem("key"),
      });

      if (response.data.success === true) {
        setRecords(response.data.data);
        setItems(response.data.data);
        setLoading(false);
      } else {
        //alert message provide
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    try {
      const response_deactive = await axios.post(
        BASE_URL + "API/api_index.php",
        {
          security_key: security_key,
          mode: "getAllStudentDeactiveWithCard",
          fk_cent_key: sessionStorage.getItem("key"),
        }
      );

      if (response_deactive.data.success === true) {
        setDeactiveRecords(response_deactive.data.data);
        setDeactiveItems(response_deactive.data.data);
        setLoading(false);
      } else {
        //alert message provide
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //Api call End

  // Sample columns configuration
  const columns = [
    {
      headerName: "ID#",
      field: "pk_student_id",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Student Name",
      field: "Student Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return `${params.row.st_first_name || ""} ${params.row.st_surname || ""
          }`;
      },
    },
    {
      headerName: "User Name",
      field: "sa_login_id",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return `${params.row.sa_login_id == null || params.row.sa_login_id == ""
            ? "-"
            : params.row.sa_login_id
          }`;
      },
    },
    {
      headerName: "Parents / Guardians",
      field: "st_is_adultlearner",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return `${params.row.st_is_adultlearner == 1
            ? "Adult Learner"
            : params.row.par_firstname == null || params.row.par_firstname == ""
              ? "-"
              : params.row.par_firstname + " " + params.row.par_surname
          }`;
      },
    },
    {
      id: "dob",
      headerName: "Date Of Birth",
      field: "st_dob",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return `${params.row.st_dob == null || params.row.st_dob == ""
            ? "-"
            : Moment(params.row.st_dob, "YYYY-MM-DD").format("DD-MM-YYYY")
          }`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === "-" || valueB === "-") {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === "-" ? 1 : -1;
        }

        const dateA = Moment(valueA, "DD-MM-YYYY");
        const dateB = Moment(valueB, "DD-MM-YYYY");

        return direction === "asc" ? dateA - dateB : dateB - dateA;
      },
    },
    {
      headerName: "Gender",
      field: "st_gender",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return `${params.row.st_gender == null || params.row.st_gender == ""
            ? "-"
            : params.row.st_gender
          }`;
      },
    },
    {
      headerName: "Year Level",
      field: "level_name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return `${params.row.level_name == null || params.row.level_name == ""
            ? "-"
            : params.row.level_name
          }`;
      },
    },
    // {
    //   name: 'Owing',
    //   // selector: '0',
    //   // sortable: true
    // },
    // {
    //   name: 'Credit',
    //   // selector: '1',
    //   // sortable: true
    // },
    // {
    //   name: 'Location',
    //   selector: row => row.st_city == null ||  row.st_city == '' ? '-' : row.st_city,
    //   sortable: true
    // },
    {
      headerName: "Illnesses",
      field: "st_illnesses",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return `${params.row.st_illnesses == null || params.row.st_illnesses == ""
            ? "-"
            : params.row.st_illnesses
          }`;
      },
    },
    {
      headerName: "Subject(s)",
      field: "subject_name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return `${params.row.subject_name == null || params.row.subject_name == ""
            ? "-"
            : params.row.subject_name
          }`;
      },
    },
    {
      field: "Card Payment Verify",
      type: "actions",

      //field: "#",
      //  type: "actions",
      headerName: "Card Payment Verify",
      headerClassName: "super-app-theme--header",
      width: 170,
      //flex: 1,
      disableExport: false,
      disableColumnFilter: false,
      disableColumnMenu: false,
      filterable: true,
      sortable: true,
      headerAlign: "left",
      renderCell: (params) => {
        const row = params.row;

        if (
          parseInt(row.stripe_setting) === 1 &&
          parseInt(row.online_payment_verified) == 0
        ) {
          return (
            <div className="d-flex pad-12">
              <Buttonmui
                variant="outlined-red"
                onClick={() => openPopupSP(row)}
                className="primary-blue  btnmr-2 my-auto "
              >
                Card Payment Verify
              </Buttonmui>
            </div>
          );
        } else if (
          parseInt(row.stripe_setting) === 1 &&
          parseInt(row.online_payment_verified) === 1 &&
          parseInt(row.card_status) === 1
        ) {
          return (
            // <div className="d-flex pad-12">
            //   <p className="text-success mb-0">Active</p>
            //   <Buttonmui
            //     variant="outlined-red"
            //     onClick={() => openPopupCC(row)}
            //     className="filled-danger my-2"
            //   >
            //     Change card
            //   </Buttonmui>
            // </div>
            <div
              className="d-flex align-items-center justify-content-between pad-12"
              style={{ gap: "8px" }}
            >
              <p className="text-success mb-0 fs-16">Active </p>

            </div>
          );
        } else if (
          parseInt(row.stripe_setting) === 1 &&
          parseInt(row.online_payment_verified) === 1 &&
          parseInt(row.card_status) === 0
        ) {
          return (
            <div className="d-flex align-items-center justify-content-between pad-12">
              <p className="text-danger mb-0 mb-0 fs-16">Inactive</p>
              {/* <Buttonmui
                variant="outlined-red"
                onClick={() => openPopupCC(row)}
                className="filled-danger my-2"
              >
                Change card
              </Buttonmui> */}
            </div>
          );
        } else {
          return (
            <div className="d-flex pad-12">
              <p className="text-danger mb-0">Fill a stripe setting</p>
            </div>
          );
        }

        //  if(parseInt(row.stripe_setting) === 1 && (row.student_pay_type === null || row.student_pay_type==='')) {
        //     return (
        //       <div className='d-flex pad-12'>
        //         <Buttonmui variant="outlined-red" onClick={() => openPopupSP(row)} className="primary-blue  btnmr-2 my-auto " >
        //           Click Payment Link
        //         </Buttonmui>
        //       </div>
        //     );
        //   }else if(parseInt(row.stripe_setting) === 1 && row.student_pay_type && parseInt(row.student_pay_type) === 1 && parseInt(row.online_payment_verified) === 1){
        //     return ( <div className='d-flex pad-12'>

        //       <Buttonmui variant="outlined-red" onClick={() => handleCancelClick(row)} className="filled-danger my-2">Cancel Online</Buttonmui>

        //      </div>);
        //   }else if(row.student_pay_type && parseInt(row.student_pay_type) === 2){
        //     return ( <div className='d-flex pad-12'>

        //       <Buttonmui variant="outlined-red" onClick={() => handleCancelOfflineClick(row)} className="filled-danger my-2">Cancel Offline</Buttonmui>

        //      </div>);
        //   }else if(row.stripe_setting == 0 && (row.student_pay_type === null || row.student_pay_type==='')) {
        //     return (
        //       <div className='d-flex pad-12'>
        //         <Buttonmui variant="outlined-red" onClick={() => handleOfflineClick(row)} className="primary-blue  btnmr-2 my-auto " >
        //           Click Offline
        //         </Buttonmui>
        //       </div>
        //     );
        //   }else{
        //     return (<div className='d-flex pad-12'><p className="text-danger">Fill a stripe setting</p></div>);
        //   }
      },
    },
    {
      field: "Card Update",
      type: "actions",

      //field: "#",
      //  type: "actions",
      headerName: "Card Update",
      headerClassName: "super-app-theme--header",
      width: 170,
      //flex: 1,
      disableExport: false,
      disableColumnFilter: false,
      disableColumnMenu: false,
      filterable: true,
      sortable: true,
      headerAlign: "left",
      renderCell: (params) => {
        const row = params.row;

        if (
          parseInt(row.stripe_setting) === 1 &&
          parseInt(row.online_payment_verified) === 1 &&
          parseInt(row.card_status) === 1
        ) {
          return (

            <div
              className="d-flex align-items-center justify-content-between pad-12"
              style={{ gap: "8px" }}
            >
              <Buttonmui
                variant="outlined-red"
                onClick={() => openPopupCC(row)}
                className="primary-blue my-2"
              >
                Update Card Details
              </Buttonmui>

            </div>
          );
        } else if (
          parseInt(row.stripe_setting) === 1 &&
          parseInt(row.online_payment_verified) === 1 &&
          parseInt(row.card_status) === 0
        ) {
          return (
            <div className="d-flex align-items-center justify-content-between pad-12">
              {/* <p className="text-danger mb-0 mb-0 fs-16">Inactive</p> */}
              <Buttonmui
                variant="outlined-red"
                onClick={() => openPopupCC(row)}
                className="primary-blue  my-2"
              >
                Update Card Details
              </Buttonmui>
            </div>
          );
        } else {
          return (
            <div className="d-flex pad-12"> -
              {/* <p className="text-danger mb-0">Fill a stripe setting</p> */}
            </div>
          );
        }

        //  if(parseInt(row.stripe_setting) === 1 && (row.student_pay_type === null || row.student_pay_type==='')) {
        //     return (
        //       <div className='d-flex pad-12'>
        //         <Buttonmui variant="outlined-red" onClick={() => openPopupSP(row)} className="primary-blue  btnmr-2 my-auto " >
        //           Click Payment Link
        //         </Buttonmui>
        //       </div>
        //     );
        //   }else if(parseInt(row.stripe_setting) === 1 && row.student_pay_type && parseInt(row.student_pay_type) === 1 && parseInt(row.online_payment_verified) === 1){
        //     return ( <div className='d-flex pad-12'>

        //       <Buttonmui variant="outlined-red" onClick={() => handleCancelClick(row)} className="filled-danger my-2">Cancel Online</Buttonmui>

        //      </div>);
        //   }else if(row.student_pay_type && parseInt(row.student_pay_type) === 2){
        //     return ( <div className='d-flex pad-12'>

        //       <Buttonmui variant="outlined-red" onClick={() => handleCancelOfflineClick(row)} className="filled-danger my-2">Cancel Offline</Buttonmui>

        //      </div>);
        //   }else if(row.stripe_setting == 0 && (row.student_pay_type === null || row.student_pay_type==='')) {
        //     return (
        //       <div className='d-flex pad-12'>
        //         <Buttonmui variant="outlined-red" onClick={() => handleOfflineClick(row)} className="primary-blue  btnmr-2 my-auto " >
        //           Click Offline
        //         </Buttonmui>
        //       </div>
        //     );
        //   }else{
        //     return (<div className='d-flex pad-12'><p className="text-danger">Fill a stripe setting</p></div>);
        //   }
      },
    },
    {
      headerName: "Stripe Status",
      field: "stripe_status",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return `${params.row.stripe_status == null || params.row.stripe_status == ""
            ? "-"
            : params.row.stripe_status
          }`;
      },
    },
    // {
    //   headerName: 'Subscription Plan',
    //   field: "Subscription",
    //   headerClassName: "super-app-theme--header",
    //   // flex: 1,
    //   width: 130,
    //   valueGetter: (params) => {
    //     return `${params.row.stripe_acount > 0 && params.row.stp_product_name && params.row.ss_price  ? params.row.stp_product_name+'($'+params.row.ss_price+')' : '-'}`;
    //   },

    // },
    {
      field: "actions",
      type: "actions",

      //field: "#",
      //  type: "actions",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      width: 250,
      disableExport: false,
      disableColumnFilter: false,
      disableColumnMenu: false,
      filterable: true,
      sortable: true,
      headerAlign: "left",
      valueGetter: (params) => {
        return `${parseInt(params.row.visibility) == 1 ? "Active" : "Inactive"
          }`;
      },
      //flex: 1,
      renderCell: (params) => {
        const row = params.row;

        if (row.pay_gateway_response_code == "Authorised") {
          return (
            <div className="d-flex  pad-12">
              <p>Completed</p>
              {parseInt(row.visibility) == 1 ? (
                <span
                  className="active-btn my-auto cursor-pointer"
                  onClick={() => handleDeleteClick(row)}
                >
                  <DoneIcon className="icon-blue  btn-icon me-1" />
                  Active
                </span>
              ) : (
                <span
                  className="deactive-btn my-auto cursor-pointer"
                  onClick={() => handleVisibleClick(row)}
                >
                  <CloseIcon className=" btn-icon me-1" />
                  Inactive
                </span>
              )}
            </div>
          );
        } else {
          return (
            <div className="d-flex pad-12">
              <Buttonmui
                variant="outlined-red"
                onClick={() => handleStudentEditClick(row)}
                className="primary-blue  btnmr-2 my-2"
              >
                Edit
              </Buttonmui>
              {/* <Buttonmui
                variant="outlined-red"
                onClick={() => openPopupGP(row)}
                className="primary-blue  btnmr-2 my-auto"
              >
                Send Enrolment
              </Buttonmui> */}
              {parseInt(row.st_enroll_status) === 1 && (
                <Buttonmui
                  variant="outlined"
                  onClick={() => openPopupGP(row)}
                  style={{
                    marginRight: "1rem",
                    marginTop: "auto",
                    marginBottom: "auto",
                    backgroundColor: "#ff9800", // Orange background
                    color: "white",
                    border: "1px solid #ff9800",
                    fontSize: "10px",
                    textTransform: "none",
                    minWidth: "max-content",
                    width: "max-content",
                    padding: "4px 6px",
                  }}
                >
                  Enrolment Sended
                </Buttonmui>
              )}

              {parseInt(row.st_enroll_status) === 2 && (
                <Buttonmui
                  variant="outlined"
                  onClick={() => openPopupGP(row)}
                  style={{
                    marginRight: "0.5rem",
                    marginTop: "auto",
                    marginBottom: "auto",
                    backgroundColor: "#28a745", // Green background
                    color: "white",
                    border: "1px solid #28a745",
                    fontSize: "10px",
                    textTransform: "none",
                    minWidth: "max-content",
                    width: "max-content",
                    padding: "4px 6px",
                  }}
                >
                  Enrolment Received
                </Buttonmui>
              )}
              {parseInt(row.st_enroll_status) !== 1 &&
                parseInt(row.st_enroll_status) !== 2 && (
                  <Buttonmui
                    variant="outlined"
                    onClick={() => openPopupGP(row)}
                    className="primary-blue  btnmr-2 my-auto"
                  >
                    Send Enrolment
                  </Buttonmui>
                )}
              {parseInt(row.visibility) == 1 ? (
                <span
                  className="active-btn my-auto cursor-pointer"
                  onClick={() => handleDeleteClick(row)}
                >
                  <DoneIcon className="icon-blue  btn-icon me-1" />
                  Active
                </span>
              ) : (
                <span
                  className="deactive-btn my-auto cursor-pointer"
                  onClick={() => handleVisibleClick(row)}
                >
                  <CloseIcon className=" btn-icon me-1" />
                  Inactive
                </span>
              )}

              {/* <Buttonmui variant="outlined-red" onClick={() => openPopupGP(row)} className="primary-blue  btnmr-2 my-auto">Send Enrolment </Buttonmui> */}
            </div>
          );
        }
      },
    },
  ];

  const columnsDeactive = [
    {
      headerName: "ID#",
      field: "pk_student_id",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 150,
    },

    {
      headerName: "Student Name",
      field: "Student Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return `${params.row.st_first_name || ""} ${params.row.st_surname || ""
          }`;
      },
    },
    {
      headerName: "User Name",
      field: "sa_login_id",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return `${params.row.sa_login_id == null || params.row.sa_login_id == ""
            ? "-"
            : params.row.sa_login_id
          }`;
      },
    },
    {
      headerName: "Parents / Guardians",
      field: "st_is_adultlearner",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return `${params.row.st_is_adultlearner == 1
            ? "Adult Learner"
            : params.row.par_firstname == null || params.row.par_firstname == ""
              ? "-"
              : params.row.par_firstname + " " + params.row.par_surname
          }`;
      },
    },
    {
      id: "dob",
      headerName: "Date Of Birth",
      field: "st_dob",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return `${params.row.st_dob == null || params.row.st_dob == ""
            ? "-"
            : Moment(params.row.st_dob, "YYYY-MM-DD").format("DD-MM-YYYY")
          }`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === "-" || valueB === "-") {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === "-" ? 1 : -1;
        }

        const dateA = Moment(valueA, "DD-MM-YYYY");
        const dateB = Moment(valueB, "DD-MM-YYYY");

        return direction === "asc" ? dateA - dateB : dateB - dateA;
      },
    },
    {
      headerName: "Gender",
      field: "st_gender",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return `${params.row.st_gender == null || params.row.st_gender == ""
            ? "-"
            : params.row.st_gender
          }`;
      },
    },
    {
      headerName: "Year Level",
      field: "level_name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return `${params.row.level_name == null || params.row.level_name == ""
            ? "-"
            : params.row.level_name
          }`;
      },
    },
    // {
    //   name: 'Owing',
    //   // selector: '0',
    //   // sortable: true
    // },
    // {
    //   name: 'Credit',
    //   // selector: '1',
    //   // sortable: true
    // },
    // {
    //   name: 'Location',
    //   selector: row => row.st_city == null ||  row.st_city == '' ? '-' : row.st_city,
    //   sortable: true
    // },
    {
      headerName: "Illnesses",
      field: "st_illnesses",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return `${params.row.st_illnesses == null || params.row.st_illnesses == ""
            ? "-"
            : params.row.st_illnesses
          }`;
      },
    },
    {
      headerName: "Subject(s)",
      field: "subject_name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return `${params.row.subject_name == null || params.row.subject_name == ""
            ? "-"
            : params.row.subject_name
          }`;
      },
    },
    {
      field: "Payment Verify",
      type: "actions",

      //field: "#",
      //  type: "actions",
      headerName: "Payment Verify",
      headerClassName: "super-app-theme--header",
      width: 170,
      //flex: 1,
      disableExport: false,
      disableColumnFilter: false,
      disableColumnMenu: false,
      filterable: true,
      sortable: true,
      headerAlign: "left",
      renderCell: (params) => {
        const row = params.row;
        if (
          parseInt(row.stripe_setting) === 1 &&
          parseInt(row.online_payment_verified) == 0
        ) {
          return (
            <div className="d-flex pad-12">
              <Buttonmui
                variant="outlined-red"
                onClick={() => openPopupSP(row)}
                className="primary-blue  btnmr-2 my-auto "
              >
                Card Payment Verify
              </Buttonmui>
            </div>
          );
        } else if (
          parseInt(row.stripe_setting) === 1 &&
          parseInt(row.online_payment_verified) === 1 &&
          parseInt(row.card_status) === 1
        ) {
          return (
            <div
              className="d-flex align-items-center justify-content-between pad-12"
              style={{ gap: "8px" }}
            >
              <p className="text-success mb-0 fs-16">Active</p>
              {/* <Buttonmui
                variant="outlined-red"
                onClick={() => openPopupCC(row)}
                className="filled-danger my-2"
              >
                Change card
              </Buttonmui> */}
            </div>
          );
        } else if (
          parseInt(row.stripe_setting) === 1 &&
          parseInt(row.online_payment_verified) === 1 &&
          parseInt(row.card_status) === 0
        ) {
          return (
            <div className="d-flex pad-12">
              <p className="text-danger">Inactive</p>
              {/* <Buttonmui
                variant="outlined-red"
                onClick={() => openPopupCC(row)}
                className="filled-danger my-2"
              >
                Change card
              </Buttonmui> */}
            </div>
          );
        } else {
          return (
            <div className="d-flex pad-12">
              <p className="text-danger">Fill a stripe setting</p>
            </div>
          );
        }
        // if(parseInt(row.stripe_setting) === 1 && (row.student_pay_type === null || row.student_pay_type==='')) {
        //   return (
        //     <div className='d-flex pad-12'>
        //       <Buttonmui variant="outlined-red" onClick={() => openPopupSP(row)} className="primary-blue  btnmr-2 my-auto " >
        //         Click Payment Link
        //       </Buttonmui>
        //     </div>
        //   );
        // }else if(parseInt(row.stripe_setting) === 1 && row.student_pay_type && parseInt(row.student_pay_type) === 1 && parseInt(row.online_payment_verified) === 1){
        //   return ( <div className='d-flex pad-12'>

        //     <Buttonmui variant="outlined-red" onClick={() => handleCancelClick(row)} className="filled-danger my-2">Cancel Online</Buttonmui>

        //    </div>);
        // }else if(row.student_pay_type && parseInt(row.student_pay_type) === 2){
        //   return ( <div className='d-flex pad-12'>

        //     <Buttonmui variant="outlined-red" onClick={() => handleCancelOfflineClick(row)} className="filled-danger my-2">Cancel Offline</Buttonmui>

        //    </div>);
        // }else if(row.stripe_setting == 0 && (row.student_pay_type === null || row.student_pay_type==='')) {
        //   return (
        //     <div className='d-flex pad-12'>
        //       <Buttonmui variant="outlined-red" onClick={() => handleOfflineClick(row)} className="primary-blue  btnmr-2 my-auto " >
        //         Click Offline
        //       </Buttonmui>
        //     </div>
        //   );
        // }else{
        //   return (<div className='d-flex pad-12'><p className="text-danger">Fill a stripe setting</p></div>);
        // }
      },
    },
    {
      field: "Card Update",
      type: "actions",

      //field: "#",
      //  type: "actions",
      headerName: "Card Update",
      headerClassName: "super-app-theme--header",
      width: 170,
      //flex: 1,
      disableExport: false,
      disableColumnFilter: false,
      disableColumnMenu: false,
      filterable: true,
      sortable: true,
      headerAlign: "left",
      renderCell: (params) => {
        const row = params.row;

        if (
          parseInt(row.stripe_setting) === 1 &&
          parseInt(row.online_payment_verified) === 1 &&
          parseInt(row.card_status) === 1
        ) {
          return (

            <div
              className="d-flex align-items-center justify-content-between pad-12"
              style={{ gap: "8px" }}
            >
              <Buttonmui
                variant="outlined-red"
                onClick={() => openPopupCC(row)}
                className="primary-blue  my-2"
              >
                Update Card Details
              </Buttonmui>

            </div>
          );
        } else if (
          parseInt(row.stripe_setting) === 1 &&
          parseInt(row.online_payment_verified) === 1 &&
          parseInt(row.card_status) === 0
        ) {
          return (
            <div className="d-flex align-items-center justify-content-between pad-12">
              {/* <p className="text-danger mb-0 mb-0 fs-16">Inactive</p> */}
              <Buttonmui
                variant="outlined-red"
                onClick={() => openPopupCC(row)}
                className="primary-blue  my-2"
              >
                Update Card Details
              </Buttonmui>
            </div>
          );
        } else {
          return (
            <div className="d-flex pad-12"> -
              {/* <p className="text-danger mb-0">Fill a stripe setting</p> */}
            </div>
          );
        }

        //  if(parseInt(row.stripe_setting) === 1 && (row.student_pay_type === null || row.student_pay_type==='')) {
        //     return (
        //       <div className='d-flex pad-12'>
        //         <Buttonmui variant="outlined-red" onClick={() => openPopupSP(row)} className="primary-blue  btnmr-2 my-auto " >
        //           Click Payment Link
        //         </Buttonmui>
        //       </div>
        //     );
        //   }else if(parseInt(row.stripe_setting) === 1 && row.student_pay_type && parseInt(row.student_pay_type) === 1 && parseInt(row.online_payment_verified) === 1){
        //     return ( <div className='d-flex pad-12'>

        //       <Buttonmui variant="outlined-red" onClick={() => handleCancelClick(row)} className="filled-danger my-2">Cancel Online</Buttonmui>

        //      </div>);
        //   }else if(row.student_pay_type && parseInt(row.student_pay_type) === 2){
        //     return ( <div className='d-flex pad-12'>

        //       <Buttonmui variant="outlined-red" onClick={() => handleCancelOfflineClick(row)} className="filled-danger my-2">Cancel Offline</Buttonmui>

        //      </div>);
        //   }else if(row.stripe_setting == 0 && (row.student_pay_type === null || row.student_pay_type==='')) {
        //     return (
        //       <div className='d-flex pad-12'>
        //         <Buttonmui variant="outlined-red" onClick={() => handleOfflineClick(row)} className="primary-blue  btnmr-2 my-auto " >
        //           Click Offline
        //         </Buttonmui>
        //       </div>
        //     );
        //   }else{
        //     return (<div className='d-flex pad-12'><p className="text-danger">Fill a stripe setting</p></div>);
        //   }
      },
    },
    // {
    //   headerName: 'Subscription Plan',
    //   field: "Subscription",
    //   headerClassName: "super-app-theme--header",
    //   flex: 1,
    //   valueGetter: (params) => {
    //     return `${params.row.stripe_acount > 0 && params.row.stp_product_name && params.row.ss_price  ? params.row.stp_product_name+'($'+params.row.ss_price+')' : '-'}`;
    //   },

    // },
    {
      headerName: "Stripe Status",
      field: "stripe_status",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return `${params.row.stripe_status == null || params.row.stripe_status == ""
            ? "-"
            : params.row.stripe_status
          }`;
      },
    },
    {
      field: "actions",
      type: "actions",

      //field: "#",
      //  type: "actions",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      width: 250,
      //flex: 1,
      disableExport: false,
      disableColumnFilter: false,
      disableColumnMenu: false,
      filterable: true,
      sortable: true,
      headerAlign: "left",
      valueGetter: (params) => {
        return `${parseInt(params.row.visibility) == 1 ? "Active" : "Inactive"
          }`;
      },
      renderCell: (params) => {
        const row = params.row;

        if (row.pay_gateway_response_code == "Authorised") {
          return (
            <div className="d-flex  pad-12">
              <p>Completed</p>
              {parseInt(row.visibility) == 1 ? (
                <span
                  className="active-btn my-auto cursor-pointer"
                  onClick={() => handleDeleteClick(row)}
                >
                  <DoneIcon className="icon-blue btn-icon me-1" />
                  Active
                </span>
              ) : (
                <span
                  className="deactive-btn my-auto cursor-pointer"
                  onClick={() => handleVisibleClick(row)}
                >
                  <CloseIcon className=" btn-icon me-1" />
                  Inactive
                </span>
              )}
            </div>
          );
        } else {
          return (
            <div className="d-flex pad-12">
              {/* <Buttonmui
                variant="outlined-red"
                onClick={() => openPopupGP(row)}
                className="primary-blue  btnmr-2 my-auto "
              >
                Send Enrolment
              </Buttonmui> */}
              {parseInt(row.st_enroll_status) === 1 && (
                <Buttonmui
                  variant="outlined"
                  onClick={() => openPopupGP(row)}
                  style={{
                    marginRight: "1rem",
                    marginTop: "auto",
                    marginBottom: "auto",
                    backgroundColor: "#ff9800", // Orange background
                    color: "white",
                    border: "1px solid #ff9800",
                    fontSize: "10px",
                    textTransform: "none",
                    minWidth: "max-content",
                    width: "max-content",
                    padding: "4px 6px",
                  }}
                >
                  Enrolment Sended
                </Buttonmui>
              )}

              {parseInt(row.st_enroll_status) === 2 && (
                <Buttonmui
                  variant="outlined"
                  onClick={() => openPopupGP(row)}
                  style={{
                    marginRight: "0.5rem",
                    marginTop: "auto",
                    marginBottom: "auto",
                    backgroundColor: "#28a745", // Green background
                    color: "white",
                    border: "1px solid #28a745",
                    fontSize: "10px",
                    textTransform: "none",
                    minWidth: "max-content",
                    width: "max-content",
                    padding: "4px 6px",
                  }}
                >
                  Enrolment Received
                </Buttonmui>
              )}
              {parseInt(row.st_enroll_status) !== 1 &&
                parseInt(row.st_enroll_status) !== 2 && (
                  <Buttonmui
                    variant="outlined"
                    onClick={() => openPopupGP(row)}
                    className="primary-blue  btnmr-2 my-auto"
                  >
                    Send Enrolment
                  </Buttonmui>
                )}
              {parseInt(row.visibility) == 1 ? (
                <span
                  className="active-btn my-auto cursor-pointer"
                  onClick={() => handleDeleteClick(row)}
                >
                  <DoneIcon className="icon-blue  btn-icon me-1" />
                  Active
                </span>
              ) : (
                <span
                  className="deactive-btn my-auto cursor-pointer"
                  onClick={() => handleVisibleClick(row)}
                >
                  <CloseIcon className=" btn-icon me-1" />
                  Inactive
                </span>
              )}

              {/* <Buttonmui variant="outlined-red" onClick={() => openPopupGP(row)} className="primary-blue  btnmr-2 my-auto">Send Enrolment </Buttonmui> */}
            </div>
          );
        }
      },
    },
  ];

  const [records, setRecords] = useState(data);
  const [Deactiverecords, setDeactiveRecords] = useState(dataDeactive);

  const openPopupGP = (dataGP) => {
    setDataGP(dataGP);
    setShowPopupGeneratePayment(!showPopupGeneratePayment);
  };
  const closePopupGeneratePayment = () => {
    setShowPopupGeneratePayment(false);
  };

  const basicSchema = yup.object().shape({
    payment_emailid: yup
      .string()
      .email("Please enter a valid email address")
      .required("Email is required.")
      .matches(
        /^[^$&+,:;=?@#|'<>.^*()%!-]+/,
        "Input cannot start with a special character"
      )
      .test("no-dot", "Please enter a valid email address", function (value) {
        return value && value.includes(".");
      }),
  });
  const initialValues = {
    /*tbl_center_master*/
    payment_emailid: DataGP && DataGP.par_emailid? DataGP.par_emailid : '', //Centre name-1
    created_by: sessionStorage.getItem("key"),
    updated_by: sessionStorage.getItem("key"),
    visibility: "1",
  };

  // const { values, handleBlur, touched, handleChange, errors, handleSubmit, setTouched, resetForm, setValues } = useFormik({
  const formik = useFormik({
    initialValues,
    validationSchema: basicSchema,
    // onSubmit,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      setIsSubmittingPayment(true);

      try {
        const responseSE = await axios.post(BASE_URL + "API/api_index.php", {
          security_key: security_key,
          fk_cent_key: sessionStorage.getItem("key"),
          mode: "sendEnrolmentMail",
          pk_student_key: DataGP.pk_student_key,
          st_email: DataGP.st_email,
          st_first_name: DataGP.st_first_name,
          st_surname: DataGP.st_surname,
          ...formValues,
        });

        if (responseSE.data.success === true) {
          // Reset the form
          //formRef.current.reset();
          if (responseSE.data.data === 1) {
            toast.success(
              "Success! Your enrolment request mail has been successfully sent.",
              {
                autoClose: 3000, // 3 seconds in this example
              }
            );
            fetchData();
            setIsSubmittingPayment(false);
            setShowPopupGeneratePayment(false);

            // window.open(
            //   responseSE.data.dataRes,
            //   "_blank",
            //   "noopener,noreferrer"
            // );
          } else {
            toast.error(
              "Failure! Your enrolment request mail has been not sent. Try again later",
              {
                autoClose: 3000, // 3 seconds in this example
              }
            );

            setTimeout(function () {
              setIsSubmittingPayment(false);
              setShowPopupGeneratePayment(false);
            }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds
          }
        } else {
          if (responseSE.data.data === 2) {
            toast.error(
              "Failure! Your enrolment request mail has been not sent. Try again later",
              {
                autoClose: 3000, // 3 seconds in this example
              }
            );
          } else {
            toast.error(
              "Failure! Your enrolment request mail has been not sent. Try again later",
              {
                autoClose: 3000, // 3 seconds in this example
              }
            );
          }

          setTimeout(function () {
            setIsSubmittingPayment(false);
            setShowPopupGeneratePayment(false);
          }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error(
          "Failure! Your enrolment request mail has been not sent. Try again later",
          {
            autoClose: 3000, // 3 seconds in this example
          }
        );

        setTimeout(function () {
          setIsSubmittingPayment(false);
          setShowPopupGeneratePayment(false);
        }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds
      } finally {
        setTimeout(function () {
          setIsSubmittingPayment(false);
          setShowPopupGeneratePayment(false);
        }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds
      }
    },
  });

  // Now, you can set variables as needed:
  const values = formik.values;
  const handleBlur = formik.handleBlur;
  const touched = formik.touched;
  const handleChange = formik.handleChange;
  const errors = formik.errors;
  const handleSubmit = formik.handleSubmit;
  const setTouched = formik.setTouched;
  const resetForm = formik.resetForm;
  const setValues = formik.setValues;
  const handleReset = formik.handleReset;

  //STRIPE PAYMENT ACTION START
  //Direct offline payment

  const handleOfflineClick = (dataSP) => {
    setDataSP(dataSP);
    setShowPopupOfflinePayment(!showPopupOfflinePayment);
  };
  const closePopupOfflinePayment = () => {
    setShowPopupOfflinePayment(false);
  };

  const basicSchemaSP = yup.object().shape({
    payment_emailid_SP: yup
      .string()
      .email("Please enter a valid email address")
      .required("Email is required.")
      .matches(
        /^[^$&+,:;=?@#|'<>.^*()%!-]+/,
        "Input cannot start with a special character"
      )
      .test("no-dot", "Please enter a valid email address", function (value) {
        return value && value.includes(".");
      }),
  });
  const initialValuesSP = {
    /*tbl_center_master*/
    payment_emailid_SP:  DataSP && DataSP.par_emailid ? DataSP.par_emailid : '', //Centre name-1
    created_by: sessionStorage.getItem("key"),
    updated_by: sessionStorage.getItem("key"),
    visibility: "1",
  };

  // const { values, handleBlur, touched, handleChange, errors, handleSubmit, setTouched, resetForm, setValues } = useFormik({
  const formikSP = useFormik({
    initialValues: initialValuesSP,
    validationSchema: basicSchemaSP,
    // onSubmit,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      setIsSubmittingSPayment(true);

      try {
        const responseSP = await axios.post(BASE_URL + "API/api_index.php", {
          security_key: security_key,
          fk_cent_key: sessionStorage.getItem("key"),
          mode: "sendStudentPaymentMailStripe",
          pk_student_key: DataSP.pk_student_key,
          st_email: DataSP.st_email,
          st_first_name: DataSP.st_first_name,
          st_surname: DataSP.st_surname,
          ...formValues,
        });

        if (responseSP.data.success === true) {
          // Reset the form
          //formRef.current.reset();
          if (responseSP.data.data === 1) {
            toast.success(
              "Success! Your payment request mail has been successfully sent.",
              {
                autoClose: 3000, // 3 seconds in this example
              }
            );

            setIsSubmittingSPayment(false);
            setShowPopupGenerateSPayment(false);

            // window.open(
            //   responseSP.data.dataRes,
            //   "_blank",
            //   "noopener,noreferrer"
            // );
          } else {
            toast.error(
              "Failure! Your enrolment request mail has been not sent. Try again later",
              {
                autoClose: 3000, // 3 seconds in this example
              }
            );

            setTimeout(function () {
              setIsSubmittingSPayment(false);
              setShowPopupGenerateSPayment(false);
            }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds
          }
        } else {
          if (responseSP.data.data === 2) {
            toast.error(
              "Failure! Your payment request mail has been not sent. Try again later",
              {
                autoClose: 3000, // 3 seconds in this example
              }
            );
          } else {
            toast.error(
              "Failure! Your payment request mail has been not sent. Try again later",
              {
                autoClose: 3000, // 3 seconds in this example
              }
            );
          }

          setTimeout(function () {
            setIsSubmittingSPayment(false);
            setShowPopupGenerateSPayment(false);
          }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error(
          "Failure! Your payment request mail has been not sent. Try again later",
          {
            autoClose: 3000, // 3 seconds in this example
          }
        );

        setTimeout(function () {
          setIsSubmittingSPayment(false);
          setShowPopupGenerateSPayment(false);
        }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds
      } finally {
        setTimeout(function () {
          setIsSubmittingSPayment(false);
          setShowPopupGenerateSPayment(false);
        }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds
      }
    },
  });

  // Now, you can set variables as needed:
  const valuesSP = formikSP.values;
  const handleBlurSP = formikSP.handleBlur;
  const touchedSP = formikSP.touched;
  const handleChangeSP = formikSP.handleChange;
  const errorsSP = formikSP.errors;
  const handleSubmitSP = formikSP.handleSubmit;
  const setTouchedSP = formikSP.setTouched;
  const resetFormSP = formikSP.resetForm;
  const setValuesSP = formikSP.setValues;
  const handleResetSP = formikSP.handleReset;




  const openPopupSP = (dataSP) => {
    setDataSP(dataSP);
    setShowPopupGenerateSPayment(!showPopupGenerateSPayment);
    formikSP.setValues({
      ...formikSP.values, // Preserve existing fields
      payment_emailid_SP: '', // Update the specific field
    });
  };
  const closePopupGenerateSPayment = () => {
    setShowPopupGenerateSPayment(false);
  };
  // console.log(formikSP.values)

  //STRIPE PAYMENT ACTION END

  //CARD CHANGE ACTION START

  const openPopupCC = (dataCC) => {
    setDataCC(dataCC);
    setShowPopupGenerateCC(!showPopupGenerateCC);
  };

  useEffect(() => {
    const fetchPaymentEmail = async () => {
      try {
        const response = await axios.post(BASE_URL + "API/api_index.php", {
          security_key: security_key,
          mode: "getexistingPaymentEmail",
          pk_student_key: DataCC.pk_student_key,
        });

        if (response.data.success) {
          const email = response.data.data?.[0]?.email_id;

          if (email !== null || email !== undefined) {
            formikCC.setFieldValue("payment_emailid_CC", email);
          } else {
            formikCC.setFieldValue("payment_emailid_CC", "");
          }
        } else {
          formikCC.setFieldValue("payment_emailid_CC", "");
        }
      } catch (error) {
        console.error("Error fetching email:", error);

        formikCC.setFieldValue("payment_emailid_CC", "");
      }
    };

    // Fetch payment email if dataCC exists
    if (DataCC?.pk_student_key) {
      fetchPaymentEmail();
    }
  }, [DataCC]);

  const closePopupGenerateCC = () => {
    setShowPopupGenerateCC(false);
  };

  const basicSchemaCC = yup.object().shape({
    payment_emailid_CC: yup
      .string()
      .email("Please enter a valid email address")
      .required("Email is required.")
      .matches(
        /^[^$&+,:;=?@#|'<>.^*()%!-]+/,
        "Input cannot start with a special character"
      )
      .test("no-dot", "Please enter a valid email address", function (value) {
        return value && value.includes(".");
      }),
  });
  const initialValuesCC = {
    /*tbl_center_master*/
    payment_emailid_CC: "",
    created_by: sessionStorage.getItem("key"),
    updated_by: sessionStorage.getItem("key"),
    visibility: "1",
  };

  // const { values, handleBlur, touched, handleChange, errors, handleSubmit, setTouched, resetForm, setValues } = useFormik({
  const formikCC = useFormik({
    initialValues: initialValuesCC,
    validationSchema: basicSchemaCC,
    // onSubmit,

    onSubmit: async (formValues) => {
      setIsSubmittingCC(true);

      try {
        const responseCC = await axios.post(BASE_URL + "API/api_index.php", {
          security_key: security_key,
          fk_cent_key: sessionStorage.getItem("key"),
          mode: "sendStudCardChangeMailStripe",
          pk_student_key: DataCC.pk_student_key,
          st_email: DataCC.st_email,
          st_first_name: DataCC.st_first_name,
          st_surname: DataCC.st_surname,
          ...formValues,
        });

        if (responseCC.data.success === true) {
          // Reset the form
          //formRef.current.reset();
          if (responseCC.data.data === 1) {
            toast.success(
              "Success! Your Card change request mail has been successfully sent.",
              {
                autoClose: 3000, // 3 seconds in this example
              }
            );

            setIsSubmittingCC(false);
            setShowPopupGenerateCC(false);

            // window.open(
            //   responseCC.data.dataRes,
            //   "_blank",
            //   "noopener,noreferrer"
            // );
          } else {
            toast.error(
              "Failure! Your card change has been not sent. Try again later",
              {
                autoClose: 3000, // 3 seconds in this example
              }
            );

            setTimeout(function () {
              setIsSubmittingCC(false);
              setShowPopupGenerateCC(false);
            }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds
          }
        } else {
          if (responseCC.data.data === 2) {
            toast.error(
              "Failure! Your card change request mail has been not sent. Try again later",
              {
                autoClose: 3000, // 3 seconds in this example
              }
            );
          } else {
            toast.error(
              "Failure! Your card change request mail has been not sent. Try again later",
              {
                autoClose: 3000, // 3 seconds in this example
              }
            );
          }

          setTimeout(function () {
            setIsSubmittingCC(false);
            setShowPopupGenerateCC(false);
          }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error(
          "Failure! Your card change request mail has been not sent. Try again later",
          {
            autoClose: 3000, // 3 seconds in this example
          }
        );

        setTimeout(function () {
          setIsSubmittingCC(false);
          setShowPopupGenerateCC(false);
        }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds
      } finally {
        setTimeout(function () {
          setIsSubmittingCC(false);
          setShowPopupGenerateCC(false);
        }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds
      }
    },
  });

  // Now, you can set variables as needed:
  const valuesCC = formikCC.values;
  const handleBlurCC = formikCC.handleBlur;
  const touchedCC = formikCC.touched;
  const handleChangeCC = formikCC.handleChange;
  const errorsCC = formikCC.errors;
  const handleSubmitCC = formikCC.handleSubmit;
  const setTouchedCC = formikCC.setTouched;
  const resetFormCC = formikCC.resetForm;
  const setValuesCC = formikCC.setValues;
  const handleResetCC = formikCC.handleReset;

  //CARD CHANGE ACTION END

  const [selectedOption, setSelectedOption] = useState("1");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const onSubmitPaymentOffline = () => {
    setIsSubmittingSPayment(true);

    const responsePayment = axios
      .post(BASE_URL + "API/api_index.php", {
        security_key: security_key,
        fk_cent_key: sessionStorage.getItem("key"),
        mode: "StudentPaymentOfflineSet",
        pk_student_key: DataSP.pk_student_key,
      })
      .then((responsedelete) => {
        if (responsedelete.data.data == 1) {
          setDeleteShow(false);
          // Handle the successful response here
          toast.success("Success! Student set payment method offline.", {
            autoClose: 3000, // 3 seconds in this example
          });
          fetchData();
          setSelectedOption("1");

          setShowPopupGenerateSPayment(false);
          setShowPopupOfflinePayment(false);

          setIsSubmittingSPayment(false);
        } else {
          toast.error(
            "Failure! Unable to Student set payment method. Try again later",
            {
              autoClose: 3000, // 3 seconds in this example
            }
          );
          setIsSubmittingSPayment(false);
        }
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        toast.error(
          "Failure! Unable to Student set payment method. Try again later",
          {
            autoClose: 3000, // 3 seconds in this example
          }
        );
        setIsSubmittingSPayment(false);
      });
  };

  const onSubmitPaymentOnline = () => {
    setIsSubmittingSPayment(true);

    const responsePayment = axios
      .post(BASE_URL + "API/api_index.php", {
        security_key: security_key,
        fk_cent_key: sessionStorage.getItem("key"),
        mode: "StudentPaymentOnlineSet",
        pk_student_key: DataSP.pk_student_key,
      })
      .then((responsedelete) => {
        if (responsedelete.data.data == 1) {
          setDeleteShow(false);
          // Handle the successful response here
          toast.success("Success! Student set payment method online.", {
            autoClose: 3000, // 3 seconds in this example
          });
          fetchData();
          setSelectedOption("1");

          setShowPopupGenerateSPayment(false);
          setShowPopupOfflinePayment(false);

          setIsSubmittingSPayment(false);
        } else {
          toast.error(
            "Failure! Unable to Student set payment method. Try again later",
            {
              autoClose: 3000, // 3 seconds in this example
            }
          );
          setIsSubmittingSPayment(false);
        }
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        toast.error(
          "Failure! Unable to Student set payment method. Try again later",
          {
            autoClose: 3000, // 3 seconds in this example
          }
        );
        setIsSubmittingSPayment(false);
      });
  };

  //Cancel subscription

  const [showCancelOfflinePayment, setCancelOfflinePayment] = useState(false);
  const [data_cancel_offline, setCancelOfflineLabelData] = useState([]);
  const [isUserCancelOfflineSubmitting, setIsUserCancelOfflineSubmitting] =
    useState(false);

  const handleCancelOfflineClick = (row) => {
    const updatedData = { ...row };
    setCancelOfflineLabelData(updatedData);

    setCancelOfflinePayment(true);
  };

  function onSubmitCancelOfflinePayment() {
    setIsUserCancelOfflineSubmitting(true);

    const responseCancelOffline = axios
      .post(BASE_URL + "API/api_index.php", {
        security_key: security_key,
        fk_cent_key: sessionStorage.getItem("key"),
        mode: "offlineCancelPaymentSet",
        pk_student_kay: data_cancel_offline.pk_student_key,
      })
      .then((responseCancelOffline) => {
        if (responseCancelOffline.data.data == 1) {
          setCancelOfflinePayment(false);
          // Handle the successful response here
          toast.success("Success! Offline payment canceled successfully!", {
            autoClose: 3000, // 3 seconds in this example
          });

          fetchData();

          setIsUserCancelOfflineSubmitting(false);
        } else {
          toast.error("Failure! Unable to payment cancel. Try again later", {
            autoClose: 3000, // 3 seconds in this example
          });
          setIsUserCancelOfflineSubmitting(false);
        }
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        toast.error("Failure! Unable to payment cancel. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });
        setIsUserCancelOfflineSubmitting(false);
      });
  }

  return (
    <>
      <ToastContainer position="top-center" />

      <Modal show={showDelete} variant="danger">
        <Modal.Header>Student</Modal.Header>

        <Modal.Body>
          <p>Are you sure? You want change to inactivate this student? </p>
        </Modal.Body>
        <Modal.Footer className="pt-0">
          <div className="mx-auto d-flex gap-2">
            <Button
              variant="contained"
              className="primary-gray"
              onClick={() => setDeleteShow(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              className="primary-filled "
              disabled={isUserDeleteSubmitting}
              onClick={() => onSubmitDelete()}
            >
              Yes
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={showVisible} variant="danger">
        <Modal.Header>Student</Modal.Header>

        <Modal.Body>
          <p>Are you sure? You want change to activate this student? </p>
        </Modal.Body>
        <Modal.Footer className="pt-0">
          <div className="mx-auto d-flex gap-2">
            <Button
              variant="contained"
              className="primary-gray"
              onClick={() => setVisibleShow(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              className="primary-filled "
              disabled={isVisibleSubmitting}
              onClick={() => onSubmitVisible()}
            >
              Yes
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={showCancelPayment} variant="danger">
        <Modal.Header>Payment</Modal.Header>

        <Modal.Body>
          <p>Are you sure? You want to cancel online payment this student? </p>
        </Modal.Body>
        <Modal.Footer className="pt-0">
          <div className="mx-auto d-flex gap-2">
            <Button
              variant="contained"
              className="primary-gray"
              onClick={() => setCancelPayment(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              className="primary-filled "
              disabled={isUserCancelSubmitting}
              onClick={() => onSubmitCancelPayment()}
            >
              Yes
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={showPopupOfflinePayment} variant="danger">
        <Modal.Header>Payment</Modal.Header>

        <Modal.Body>
          <p>Are you sure? You want to offline payment this student? </p>
        </Modal.Body>
        <Modal.Footer className="pt-0">
          <div className="mx-auto d-flex gap-2">
            <Button
              variant="contained"
              className="primary-gray"
              onClick={() => setShowPopupOfflinePayment(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              className="primary-filled "
              disabled={isSubmittingSPayment}
              onClick={() => onSubmitPaymentOffline()}
            >
              Yes
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={showCancelOfflinePayment} variant="danger">
        <Modal.Header>Payment</Modal.Header>

        <Modal.Body>
          <p>Are you sure? You want to cancel offline payment this student? </p>
        </Modal.Body>
        <Modal.Footer className="pt-0">
          <div className="mx-auto d-flex gap-2">
            <Button
              variant="contained"
              className="primary-gray"
              onClick={() => setCancelOfflinePayment(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              className="primary-filled "
              disabled={isUserCancelOfflineSubmitting}
              onClick={() => onSubmitCancelOfflinePayment()}
            >
              Yes
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <div className="page-wrapper">
        {/* --------------Page Start--------------- */}

        {/* --------------Section Start--------------- */}

        <div className="mb-3 d-flex justify-content-between align-items-center">
          <div>
            <h5 className="page-title"> Students List </h5>

            <div role="presentation">
              <Breadcrumbs aria-label="breadcrumb" className="my-breadcrumbs">
                <NavLink underline="hover" color="inherit" to="/calendar">
                  {" "}
                  Home
                </NavLink>
                <Typography color="text.primary">Students</Typography>
              </Breadcrumbs>
            </div>
          </div>
          <div className="d-flex align-items-center gap-2">
            {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}
            <NavLink
              className="primary-filled rounded-10 p-2"
              to="/add-student"
            >
              <AddIcon className="btn-icon" /> New Student
            </NavLink>
          </div>
        </div>
        <Card className="my-card p-1 bg-white">
          <Card.Body>
            <Tab.Container defaultActiveKey="first">
              {/* -------------Content Start--------------- */}

              <Row>
                <Col xs="12">
                  <div className="">
                    <Row className="justify-content-center">
                      {/* --------------Table Content Start--------------- */}
                      <Col sm={12}>
                        {/* <div className="d-flex flex-wrap justify-content-end align-items-center mb-4">




                          <Tab.Content>
                            <Tab.Pane eventKey="first">

                              <div class="form-group has-search my-auto">
                                <span class="fa fa-search form-control-feedback"></span>
                                <input type="text" class="form-control mb-0" onChange={handleFilter} />
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                              <div class="form-group has-search my-auto">
                                <span class="fa fa-search form-control-feedback"></span>
                                <input type="text" class="form-control mb-0" onChange={handleFilterDeactive} />
                              </div>
                            </Tab.Pane>
                          </Tab.Content>
                        </div> */}
                        <Nav variant="pills" className="mb-3 text-dark ">
                          <div className="d-flex justify-content-between">
                            <Nav.Item>
                              <Nav.Link
                                eventKey="first"
                                className="px-4 py-2 text-dark me-2"
                                onClick={handleClick}
                              >
                                Active
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="second"
                                className="px-4 py-2 text-dark"
                                onClick={handleClick}
                              >
                                Inactive
                              </Nav.Link>
                            </Nav.Item>
                          </div>
                        </Nav>
                      </Col>
                      {/* --------------Table Content Start--------------- */}

                      {/* --------------Table Content Start--------------- */}
                      <Col sm={12}>
                        {showPopupGeneratePayment && (
                          <Dialog
                            open={showPopupGeneratePayment}
                            onClose={closePopupGeneratePayment}
                          >
                            <DialogTitle>
                              Send Mail ({DataGP.st_first_name}{" "}
                              {DataGP.st_surname})
                            </DialogTitle>
                            <DialogContent>
                              <Form
                                autoComplete="off"
                                onSubmit={handleSubmit}
                                ref={formRef}
                              >
                                <Form.Group>
                                  <Form.Label>Email</Form.Label>
                                  <Form.Control
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="payment_emailid"
                                    value={values.payment_emailid}
                                    className={
                                      errors.payment_emailid &&
                                        touched.payment_emailid
                                        ? "mb-0  input-error"
                                        : ""
                                    }
                                  />
                                  {errors.payment_emailid &&
                                    touched.payment_emailid && (
                                      <span className="error clearfix ">
                                        {errors.payment_emailid}
                                      </span>
                                    )}

                                  <Button
                                    variant="contained"
                                    className="primary-gray"
                                    onClick={() =>
                                      setShowPopupGeneratePayment(false)
                                    }
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    variant="contained"
                                    type="submit"
                                    className="primary-filled "
                                    disabled={isSubmittingPayment}
                                  >
                                    Send
                                  </Button>
                                </Form.Group>
                              </Form>
                            </DialogContent>
                          </Dialog>
                        )}

                        {/* {showPopupGenerateSPayment && (
                          <Dialog open={showPopupGenerateSPayment} onClose={closePopupGenerateSPayment}>
                            <DialogTitle>Stripe Payment Verification <br></br>({DataSP.st_first_name}   {DataSP.st_surname})</DialogTitle>
                            
                            <DialogContent>
                               
                              <Form autoComplete="off" onSubmit={handleSubmitSP} ref={formRefSP}>
                                <Form.Group >
                                  <Form.Label>Email</Form.Label>
                                  <Form.Control
                                    type="text"

                                    onChange={handleChangeSP}
                                    onBlur={handleBlurSP}
                                    id="payment_emailid_SP"
                                    value={valuesSP.payment_emailid_SP}
                                    className={errorsSP.payment_emailid_SP && touchedSP.payment_emailid_SP ? "mb-0  input-error" : ""}
                                  />
                                  {errorsSP.payment_emailid_SP && touchedSP.payment_emailid_SP && <span className="error clearfix ">{errorsSP.payment_emailid_SP}</span>}

                                  <Button variant="contained" className="primary-gray" onClick={() => setShowPopupGenerateSPayment(false)}>Cancel</Button>
                                  <Button variant="contained" type='submit' className="primary-filled " disabled={isSubmittingSPayment} >Send</Button>
                                </Form.Group>
                              </Form>

                              
                            </DialogContent>
                          </Dialog>
                        )} */}
                        {console.log(DataSP)}
                        {showPopupGenerateSPayment && (
                          <Dialog
                            open={showPopupGenerateSPayment}
                            onClose={closePopupGenerateSPayment}
                          >
                            <DialogTitle>
                              Card Payment Verification({DataSP.st_first_name}{" "}
                              {DataSP.st_surname})
                            </DialogTitle>

                            <DialogContent>
                              <Form
                                autoComplete="off"
                                onSubmit={handleSubmitSP}
                                ref={formRefSP}
                              >
                                <Form.Group>
                                  <Form.Label>Email</Form.Label>
                                  <Form.Control
                                    type="text"
                                    onChange={handleChangeSP}
                                    onBlur={handleBlurSP}
                                    id="payment_emailid_SP"
                                    value={valuesSP.payment_emailid_SP}
                                    className={
                                      errorsSP.payment_emailid_SP &&
                                        touchedSP.payment_emailid_SP
                                        ? "mb-0  input-error"
                                        : ""
                                    }
                                  />
                                  {errorsSP.payment_emailid_SP &&
                                    touchedSP.payment_emailid_SP && (
                                      <span className="error clearfix ">
                                        {errorsSP.payment_emailid_SP}
                                      </span>
                                    )}

                                  <Button
                                    variant="contained"
                                    className="primary-gray"
                                    onClick={() =>
                                      setShowPopupGenerateSPayment(false)
                                    }
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    variant="contained"
                                    type="submit"
                                    className="primary-filled "
                                    disabled={isSubmittingSPayment}
                                  >
                                    Send
                                  </Button>
                                </Form.Group>
                              </Form>

                              {/* <div className="d-flex gap-2 mb-3">
                                 
                                  <div>
                                    <label>
                                      <input
                                        type="radio"
                                        value="1"
                                        checked={selectedOption === '1'}
                                        onChange={handleOptionChange}
                                      />
                                      Online Payment
                                    </label>
                                  </div>
                                  <div>
                                    <label>
                                      <input
                                        type="radio"
                                        value="2"
                                        checked={selectedOption === '2'}
                                        onChange={handleOptionChange}
                                      />
                                      Offline Payment
                                    </label>
                                  </div>
                                </div> */}

                              {/* {selectedOption === '1' && !DataSP.online_payment_verified && parseInt(DataSP.online_payment_verified) <= 0 ?
                              <Form autoComplete="off" onSubmit={handleSubmitSP} ref={formRefSP}>
                                <Form.Group >
                                  <Form.Label>Email</Form.Label>
                                  <Form.Control
                                    type="text"

                                    onChange={handleChangeSP}
                                    onBlur={handleBlurSP}
                                    id="payment_emailid_SP"
                                    value={valuesSP.payment_emailid_SP}
                                    className={errorsSP.payment_emailid_SP && touchedSP.payment_emailid_SP ? "mb-0  input-error" : ""}
                                  />
                                  {errorsSP.payment_emailid_SP && touchedSP.payment_emailid_SP && <span className="error clearfix ">{errorsSP.payment_emailid_SP}</span>}

                                  <Button variant="contained" className="primary-gray" onClick={() => setShowPopupGenerateSPayment(false)}>Cancel</Button>
                                  <Button variant="contained" type='submit' className="primary-filled " disabled={isSubmittingSPayment} >Send</Button>
                                </Form.Group>
                              </Form>

                              
                              : selectedOption === '1' && DataSP.online_payment_verified && parseInt(DataSP.online_payment_verified) > 0 ? 
                                <><p>Note: Online payment has already been verified, <br></br>so not provide email box.</p><br></br><Button variant="contained" className="primary-gray" onClick={() => setShowPopupGenerateSPayment(false)}>Cancel</Button>
                                <Button variant="contained" type='button' className="primary-filled " onClick={() => onSubmitPaymentOnline()} disabled={isSubmittingSPayment} >Save</Button></>
                              :  <><Button variant="contained" className="primary-gray" onClick={() => setShowPopupGenerateSPayment(false)}>Cancel</Button>
                              <Button variant="contained" type='button' className="primary-filled " onClick={() => onSubmitPaymentOffline()} disabled={isSubmittingSPayment} >Save</Button></>
                            } */}
                            </DialogContent>
                          </Dialog>
                        )}

                        {showPopupGenerateCC && (
                          <Dialog
                            open={showPopupGenerateCC}
                            onClose={closePopupGenerateCC}
                          >
                            <DialogTitle>
                              Card change ({DataCC.st_first_name}{" "}
                              {DataCC.st_surname})
                            </DialogTitle>

                            <DialogContent>
                              <Form
                                autoComplete="off"
                                onSubmit={handleSubmitCC}
                                ref={formRefCC}
                              >
                                <Form.Group>
                                  <Form.Label>Email</Form.Label>
                                  <Form.Control
                                    type="text"
                                    onChange={handleChangeCC}
                                    onBlur={handleBlurCC}
                                    id="payment_emailid_CC"
                                    value={valuesCC.payment_emailid_CC}
                                    className={
                                      errorsCC.payment_emailid_CC &&
                                        touchedCC.payment_emailid_CC
                                        ? "mb-0  input-error"
                                        : ""
                                    }
                                  />
                                  {errorsCC.payment_emailid_CC &&
                                    touchedCC.payment_emailid_CC && (
                                      <span className="error clearfix ">
                                        {errorsCC.payment_emailid_CC}
                                      </span>
                                    )}

                                  <Button
                                    variant="contained"
                                    className="primary-gray"
                                    onClick={() =>
                                      setShowPopupGenerateCC(false)
                                    }
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    variant="contained"
                                    type="submit"
                                    className="primary-filled "
                                    disabled={isSubmittingCC}
                                  >
                                    Send
                                  </Button>
                                </Form.Group>
                              </Form>
                            </DialogContent>
                          </Dialog>
                        )}
                        <Tab.Content>
                          <Tab.Pane eventKey="first">
                            <Box
                              sx={{
                                height: "fit-content",
                                width: "100%",
                                "& .actions": {
                                  color: "text.secondary",
                                },
                                "& .textPrimary": {
                                  color: "text.primary",
                                },
                                "& .super-app-theme--header": {
                                  backgroundColor: "#f4f4f4",
                                },
                                "& .MuiDataGrid-row": {
                                  border: "1px solid #eff0f2",
                                },
                                "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus":
                                {
                                  outline: "none !important",
                                },
                                "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
                                {
                                  outline: "none !important",
                                },
                              }}
                              style={{ height: "100%", width: "100%" }}
                            >
                              <DataGrid
                                sx={{
                                  // disable cell selection style
                                  ".MuiDataGrid-cell:focus": {
                                    outline: "none",
                                  },
                                  // pointer cursor on ALL rows
                                  "& .MuiDataGrid-row:hover": {
                                    cursor: "pointer",
                                  },
                                }}
                                //sx={{ border: "none", m: 2 }}
                                autoHeight
                                slots={{
                                  loadingOverlay: LinearProgress,
                                  //noRowsOverlay: CustomNoRowsOverlay,
                                  toolbar: GridToolbar,
                                  // toolbar: EditToolbar,
                                }}
                                slotProps={{
                                  toolbar: {
                                    csvOptions: { fileName: "StudentList" },
                                    printOptions: {
                                      pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`,
                                      disableToolbarButton: true,
                                      hideFooter: true,
                                      hideToolbar: true,
                                      fileName: "StudentList",
                                    },
                                    records,
                                    showQuickFilter: true,
                                  },
                                }}
                                initialState={{
                                  ...records.initialState,
                                  pagination: {
                                    paginationModel: { pageSize: 10 },
                                  },
                                }}
                                showQuickFilter={true}
                                pageSizeOptions={[10, 25, 50, 100]}
                                loading={loading}
                                rows={records.map((row, index) => ({
                                  ...row,
                                  id: `row-${index}`,
                                }))}
                                columns={columns}
                                rowHeight={60}
                                density="compact"
                                disableRowSelectionOnClick
                                showCellVerticalBorder={true}
                                showColumnVerticalBorder={true}
                                // editMode="row"
                                onRowClick={(params) =>
                                  handleStudentViewClick(params.row)
                                }
                              // isRowSelectable={(params: GridRowParams) => handleStudentViewClick(params.row)}
                              // rowModesModel={rowModesModel}
                              // onRowModesModelChange={handleRowModesModelChange}
                              // // processRowUpdate={processRowUpdate}
                              // getRowId={(row) => row.pk_student_id}
                              />
                            </Box>
                          </Tab.Pane>
                          <Tab.Pane eventKey="second">
                            <Box
                              sx={{
                                height: "fit-content",
                                width: "100%",
                                "& .actions": {
                                  color: "text.secondary",
                                },
                                "& .textPrimary": {
                                  color: "text.primary",
                                },
                                "& .super-app-theme--header": {
                                  backgroundColor: "#f4f4f4",
                                },
                              }}
                              style={{ height: "100%", width: "100%" }}
                            >
                              <DataGrid
                                sx={{
                                  // disable cell selection style
                                  ".MuiDataGrid-cell:focus": {
                                    outline: "none",
                                  },
                                  // pointer cursor on ALL rows
                                  "& .MuiDataGrid-row:hover": {
                                    cursor: "pointer",
                                  },
                                }}
                                //sx={{ border: "none", m: 2 }}
                                autoHeight
                                slots={{
                                  loadingOverlay: LinearProgress,
                                  //noRowsOverlay: CustomNoRowsOverlay,
                                  toolbar: GridToolbar,
                                  // toolbar: EditToolbar,
                                }}
                                slotProps={{
                                  toolbar: {
                                    csvOptions: { fileName: "StudentList" },
                                    printOptions: {
                                      pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`,
                                      disableToolbarButton: true,
                                      hideFooter: true,
                                      hideToolbar: true,
                                      fileName: "StudentList",
                                    },
                                    Deactiverecords,
                                    showQuickFilter: true,
                                  },
                                }}
                                initialState={{
                                  ...Deactiverecords.initialState,
                                  pagination: {
                                    paginationModel: { pageSize: 10 },
                                  },
                                }}
                                loading={loading}
                                pageSizeOptions={[10, 25, 50, 100]}
                                // loading={loading}
                                rows={Deactiverecords.map((row, index) => ({
                                  ...row,
                                  id: `row-${index}`,
                                }))}
                                columns={columnsDeactive}
                                rowHeight={60}
                                density="compact"
                                disableRowSelectionOnClick
                                showCellVerticalBorder={true}
                                showColumnVerticalBorder={true}
                                // editMode="row"

                                onRowClick={(params) =>
                                  handleStudentViewClick(params.row)
                                }
                              // isRowSelectable={(params: GridRowParams) => handleStudentViewClick(params.row)}
                              // rowModesModel={rowModesModel}
                              // onRowModesModelChange={handleRowModesModelChange}
                              // // processRowUpdate={processRowUpdate}
                              // getRowId={(row) => row.pk_student_id}
                              />
                            </Box>
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                      {/* --------------Table Content Start--------------- */}
                    </Row>
                  </div>
                </Col>
              </Row>

              {/* --------------Content End--------------- */}
            </Tab.Container>
          </Card.Body>
        </Card>

        {/* --------------Section End--------------- */}

        {/* --------------Page End--------------- */}
      </div>
    </>
  );
};

export default Student;
