/*
Purpose: View details of student
Designed: BY R.Sanjai
Date:22/08/2023 
Project: TCMS
*/

import { useNavigate, useLocation, NavLink } from "react-router-dom";


import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Select from 'react-select';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'; // Import CSS styles for the phone input


import { FiEye, FiEyeOff } from "react-icons/fi"; // Assuming you've imported the eye icons

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { security_key, options_time_zone, options_countries, options_weeklydays,options_time_zone_identifier, BASE_URL } from "../global";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const basicSchema = yup.object().shape({
    cent_timezone: yup.mixed().required("Timezone is required."),
    cent_timezone_identifier: yup.mixed().required("Timezone identifier is required."),
    cent_country: yup.mixed().required("Country is required."),
    //cent_dayofweek: yup.mixed().required("Weekly days is required."),
    cent_address: yup.string().min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").required("Address is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
    cent_country: yup.mixed().required("Country is required."),
    cent_city: yup.string().min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").required(" City/Town is  required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
    cent_state: yup.string().min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").required("State is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
    cent_zip:  yup.string().min(4, "Minimum 4 characters is required.").max(8, "Maximum 8 characters is required.").required("Postcode is required.").matches(/^[a-zA-Z0-9\/-]+$/, "Allow only letters, numbers, - and /."),
    cent_smtp_username: yup.string().min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").required("From Email Username is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
    cent_smtp_password: yup.string().min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").required("From Email Password is required."),
    cent_smtp_host: yup.string().min(2, "Minimum 2 characters is required.").max(300, "Maximum 300 characters is required.").required("From Email Host is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
    cent_smtp_secure: yup.string().min(2, "Minimum 2 characters is required.").max(300, "Maximum 300 characters is required.").required("From Email Secure is required.").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
    cent_smtp_port: yup.string().matches(/^[0-9]+$/, "Must be only digits").min(3, 'Must be exactly 3 digits').max(3, 'Must be exactly 3 digits').required('From Email Port is required'),
    //  cent_phone: yup.mixed().required("Phone is required."),
    // cent_emailid: yup.string().email("Please enter a valid email address").required("Email is required."),
    cent_emailid: yup.string()
    .email('Please enter a valid email address').matches(/^[^$&+,:;=?@#|'<>.^*()%!-]+/, 'Input cannot start with a special character').test('no-dot', 'Please enter a valid email address', function (value) {
        return value && value.includes('.');
      }).required('Email is required'),
    cent_busvat: yup.string().required("Business / VAT is required."),
    cent_website: yup.string().matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/, 'Enter correct url!'
    ),
    // cent_password: yup.string().min(5, "Minimum 5 characters is required.").required("Password is required."),

    // cent_esign: yup.mixed().required("Email signature is required."),
    // cent_elesign: yup.mixed().required("Electronic signature is required."),
    //cent_location: yup.string().required("Location is required."),
    cw_number_countryCode: yup.string().notRequired(),
    cw_number: yup
    .string()
    .test(
      'minDigits',
      'Minimum 5 digits required',
      function (value) {
        const { cw_number_countryCode } = this.parent;
        const countryCodeLength = cw_number_countryCode ? cw_number_countryCode.length : 0;
        const phoneLength = value ? value.length : 0;
        const difference = phoneLength - countryCodeLength;

        // Check if the difference is less than 5
        return difference >= 5 || difference === 0;
      }
    ).required("WhatsApp number is required."),
    cent_otp: yup.string().matches(/^[0-9]+$/, "Must be only digits").min(6, 'Must be exactly 6 digits').max(6, 'Must be exactly 6 digits').required('OTP is required')
});



function CentreInfo() {
    const location = useLocation();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const formRef = useRef(null);


    const [CentreData, setCentreData] = useState([]);

    //const [id, setId] = useState('');

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const extractedId = urlParams.get("id");
        if (extractedId !== '') {
            //setId(extractedId);
            fetchData(extractedId);
        }
    }, [location]);
    const [profileimageUrl, setProfileImageUrl] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [imageUrlelecsig, setImageUrlelecsig] = useState('');
    //Api call start


    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };




    const fetchData = async (id) => {
        try {
            const response = await axios.post(BASE_URL + 'API/api_index.php', {
                security_key: security_key,
                mode: "getSingleCenterDetailsinfo",
                center_key: sessionStorage.getItem('key'),
                fk_ci_id: sessionStorage.getItem('user_id'),
                user_type: sessionStorage.getItem('user_type'),
            });

            if (response.data.success === true) {
                if (response.data.data.length > 0) {
                    setCentreData(response.data.data[0]);

                    if (response.data.data[0]['cent_email_sig'] != null && response.data.data[0]['cent_email_sig'] != '') {
                        const fullImageUrl = `${response.data.data[0]['cent_email_sig']}`;
                        setImageUrl(fullImageUrl);
                    }
                    if (response.data.data[0]['cent_elec_sig'] != null && response.data.data[0]['cent_elec_sig'] != '') {
                        const fullImageUrlelec_sig = `${response.data.data[0]['cent_elec_sig']}`;
                        setImageUrlelecsig(fullImageUrlelec_sig);
                    }
                    if (response.data.data[0]['cent_profile'] != null && response.data.data[0]['cent_profile'] != '') {
                        const fullImageUrlprofimage = `${response.data.data[0]['cent_profile']}`;
                        setProfileImageUrl(fullImageUrlprofimage);

                        sessionStorage.setItem('cent_profile', response.data.data[0]['cent_profile']);

                    }
                   
                }

               
            } else {
                // Handle unsuccessful response, e.g., show an alert to the user.
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };




    const onSubmit = async (formValues) => {
        // Disable the submit button and reset alerts
        setIsSubmitting(true);


        // if (!imageUrl && !imageUrlelecsig) {

        //     try {
        //         // Create a FormData object to send the form data, including the image file
        //         const formData = new FormData();
        //         formData.append("mode", "file_s3_upload_image");
        //         formData.append("image", formValues.cent_esign); // Append the image file
        //         const response_file = await axios.post(BASE_URL+'API/upload/index.php', formData);

        //         const formData1 = new FormData();
        //         formData1.append("mode", "file_s3_upload_image");
        //         formData1.append("image", formValues.cent_elesign); // Append the image file
        //         const response_file1 = await axios.post(BASE_URL+'API/upload/index.php', formData1);


        //         // Handle the API response here
        //         if (response_file.data.success === true && response_file1.data.success === true) {

        //             try {
        //                 const response = await axios.post(BASE_URL+"API/api_index.php", {
        //                     security_key: security_key,
        //                     mode: "updateCenterDetails",
        //                     email_sig: response_file.data.data,
        //                     elec_sig: response_file1.data.data,
        //                     ...formValues,
        //                 });

        //                 if (response.data.success === true) {
        //                     if (response.data.data === 1) {
        //                         //resetFormFields(resetForm, setValues, initialValues);
        //                         toast.success("Success! Your form was submitted", {
        //                             autoClose: 3000, // 3 seconds in this example
        //                         });

        //                         if (formValues.cent_country != null && formValues.cent_country != '' && formValues.cent_country == '1') {
        //                             sessionStorage.setItem('country_alph_code', 'SG');
        //                             sessionStorage.setItem('country_code', formValues.cent_country);
        //                             sessionStorage.setItem('currency_code', 'SGD');   

        //                         } else if (formValues.cent_country != null && formValues.cent_country != '' && formValues.cent_country == '183') {
        //                             sessionStorage.setItem('country_alph_code', 'GB');
        //                             sessionStorage.setItem('country_code', formValues.cent_country);
        //                             sessionStorage.setItem('currency_code', 'GBP');
        //                         } else if (formValues.cent_country != null && formValues.cent_country != '' && formValues.cent_country == '184') {
        //                             sessionStorage.setItem('country_alph_code', 'NZ');
        //                             sessionStorage.setItem('country_code', formValues.cent_country);
        //                             sessionStorage.setItem('currency_code', 'NZD');
        //                         } else if (formValues.cent_country != null && formValues.cent_country != '' && formValues.cent_country == '185') {
        //                             sessionStorage.setItem('country_alph_code', 'AU');
        //                             sessionStorage.setItem('country_code', formValues.cent_country);
        //                             sessionStorage.setItem('currency_code', 'AUD');
        //                         } else {
        //                             sessionStorage.setItem('country_alph_code', 'SG');
        //                             sessionStorage.setItem('country_code', '1');
        //                             sessionStorage.setItem('currency_code', 'SGD');
        //                         }

        //                         // After 3 seconds, redirect to another page
        //                         setTimeout(function () {
        //                             navigate("/add-centre-info");
        //                         }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds

        //                         setIsSubmitting(false); // Re-enable the submit button
        //                     } else {
        //                         toast.error("Oops! Something went wrong.", {
        //                             autoClose: 3000, // 3 seconds in this example
        //                         });

        //                         setIsSubmitting(false); // Re-enable the submit button
        //                     }

        //                 } else {

        //                     toast.error("Oops! Something went wrong. Please Contact TCMS Team", {
        //                         autoClose: 3000, // 3 seconds in this example
        //                     });

        //                     setIsSubmitting(false); // Re-enable the submit button

        //                 }
        //             } catch (error) {
        //                 console.error("Error fetching data:", error);
        //                 toast.error("Oops! Something went wrong. Please Contact TCMS Team", {
        //                     autoClose: 3000, // 3 seconds in this example
        //                 });

        //                 setIsSubmitting(false); // Re-enable the submit button

        //             } finally {
        //                 setIsSubmitting(false); // Re-enable the submit button

        //             }


        //         } else {

        //         }

        //         // You can also perform further actions based on the API response
        //         // For example, show a success message or redirect to another page.
        //     } catch (error) {
        //         // Handle any API request errors here
        //         toast.error("Oops! Something went wrong. Please Contact TCMS Team", {
        //             autoClose: 3000, // 3 seconds in this example
        //         });

        //         setIsSubmitting(false); // Re-enable the submit button


        //     }



        // }
        // else if (!imageUrl) {

        //     try {
        //         // Create a FormData object to send the form data, including the image file


        //         const formData = new FormData();
        //         formData.append("mode", "file_s3_upload_image");
        //         formData.append("image", formValues.cent_esign); // Append the image file
        //         const response_file = await axios.post(BASE_URL+'API/upload/index.php', formData);

        //         // Handle the API response here
        //         if (response_file.data.success === true) {

        //             try {
        //                 const response = await axios.post(BASE_URL+"API/api_index.php", {
        //                     security_key: security_key,
        //                     mode: "updateCenterDetails",
        //                     email_sig: response_file.data.data,
        //                     elec_sig: formValues.cent_elesign,

        //                     ...formValues,
        //                 });

        //                 if (response.data.success === true) {
        //                     if (response.data.data === 1) {
        //                         //resetFormFields(resetForm, setValues, initialValues);
        //                         toast.success("Success! Your form was submitted", {
        //                             autoClose: 3000, // 3 seconds in this example
        //                         });
        //                         //sessionStorage.setItem('country_code', formValues.cent_country);

        //                         if (formValues.cent_country != null && formValues.cent_country != '' && formValues.cent_country == '1') {
        //                             sessionStorage.setItem('country_alph_code', 'SG');
        //                             sessionStorage.setItem('country_code', formValues.cent_country);
        //                             sessionStorage.setItem('currency_code', 'SGD');   
        //                         } else if (formValues.cent_country != null && formValues.cent_country != '' && formValues.cent_country == '183') {
        //                             sessionStorage.setItem('country_alph_code', 'GB');
        //                             sessionStorage.setItem('country_code', formValues.cent_country);
        //                             sessionStorage.setItem('currency_code', 'GBP');
        //                         } else if (formValues.cent_country != null && formValues.cent_country != '' && formValues.cent_country == '184') {
        //                             sessionStorage.setItem('country_alph_code', 'NZ');
        //                             sessionStorage.setItem('country_code', formValues.cent_country);
        //                             sessionStorage.setItem('currency_code', 'NZD');
        //                         } else if (formValues.cent_country != null && formValues.cent_country != '' && formValues.cent_country == '185') {
        //                             sessionStorage.setItem('country_alph_code', 'AU');
        //                             sessionStorage.setItem('country_code', formValues.cent_country);
        //                             sessionStorage.setItem('currency_code', 'AUD');
        //                         } else {
        //                             sessionStorage.setItem('country_alph_code', 'SG');
        //                             sessionStorage.setItem('country_code', '1');
        //                             sessionStorage.setItem('currency_code', 'SGD');
        //                         }
        //                         // After 3 seconds, redirect to another page
        //                         setTimeout(function () {
        //                             navigate("/add-centre-info");
        //                         }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds

        //                         setIsSubmitting(false); // Re-enable the submit button
        //                     } else {
        //                         toast.error("Oops! Something went wrong.", {
        //                             autoClose: 3000, // 3 seconds in this example
        //                         });

        //                         setIsSubmitting(false); // Re-enable the submit button
        //                     }

        //                 } else {

        //                     toast.error("Oops! Something went wrong. Please Contact TCMS Team", {
        //                         autoClose: 3000, // 3 seconds in this example
        //                     });

        //                     setIsSubmitting(false); // Re-enable the submit button

        //                 }
        //             } catch (error) {
        //                 console.error("Error fetching data:", error);
        //                 toast.error("Oops! Something went wrong. Please Contact TCMS Team", {
        //                     autoClose: 3000, // 3 seconds in this example
        //                 });

        //                 setIsSubmitting(false); // Re-enable the submit button

        //             } finally {
        //                 setIsSubmitting(false); // Re-enable the submit button

        //             }


        //         } else {

        //         }

        //         // You can also perform further actions based on the API response
        //         // For example, show a success message or redirect to another page.
        //     } catch (error) {
        //         // Handle any API request errors here
        //         toast.error("Oops! Something went wrong. Please Contact TCMS Team", {
        //             autoClose: 3000, // 3 seconds in this example
        //         });

        //         setIsSubmitting(false); // Re-enable the submit button


        //     }



        // }
        // else if (!imageUrlelecsig) {

        //     try {
        //         // Create a FormData object to send the form data, including the image file


        //         const formData1 = new FormData();
        //         formData1.append("mode", "file_s3_upload_image");
        //         formData1.append("image", formValues.cent_elesign); // Append the image file
        //         const response_file1 = await axios.post(BASE_URL+'API/upload/index.php', formData1);

        //         // Handle the API response here
        //         if (response_file1.data.success === true) {

        //             try {
        //                 const response = await axios.post(BASE_URL+"API/api_index.php", {
        //                     security_key: security_key,
        //                     mode: "updateCenterDetails",
        //                     elec_sig: response_file1.data.data,
        //                     email_sig: formValues.cent_esign,
        //                     ...formValues,
        //                 });

        //                 if (response.data.success === true) {
        //                     if (response.data.data === 1) {
        //                         //resetFormFields(resetForm, setValues, initialValues);
        //                         toast.success("Success! Your form was submitted", {
        //                             autoClose: 3000, // 3 seconds in this example
        //                         });
        //                         if (formValues.cent_country != null && formValues.cent_country != '' && formValues.cent_country == '1') {
        //                             sessionStorage.setItem('country_alph_code', 'SG');
        //                             sessionStorage.setItem('country_code', formValues.cent_country);
        //                             sessionStorage.setItem('currency_code', 'SGD');
        //                         } else if (formValues.cent_country != null && formValues.cent_country != '' && formValues.cent_country == '183') {
        //                             sessionStorage.setItem('country_alph_code', 'GB');
        //                             sessionStorage.setItem('country_code', formValues.cent_country);
        //                             sessionStorage.setItem('currency_code', 'GBP');
        //                         } else if (formValues.cent_country != null && formValues.cent_country != '' && formValues.cent_country == '184') {
        //                             sessionStorage.setItem('country_alph_code', 'NZ');
        //                             sessionStorage.setItem('country_code', formValues.cent_country);
        //                             sessionStorage.setItem('currency_code', 'NZD');
        //                         } else if (formValues.cent_country != null && formValues.cent_country != '' && formValues.cent_country == '185') {
        //                             sessionStorage.setItem('country_alph_code', 'AU');
        //                             sessionStorage.setItem('country_code', formValues.cent_country);
        //                             sessionStorage.setItem('currency_code', 'AUD');
        //                         } else {
        //                             sessionStorage.setItem('country_alph_code', 'SG');
        //                             sessionStorage.setItem('country_code', '1');
        //                             sessionStorage.setItem('currency_code', 'SGD');
        //                         }

        //                         // After 3 seconds, redirect to another page
        //                         setTimeout(function () {
        //                             navigate("/add-centre-info");
        //                         }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds

        //                         setIsSubmitting(false); // Re-enable the submit button
        //                     } else {
        //                         toast.error("Oops! Something went wrong.", {
        //                             autoClose: 3000, // 3 seconds in this example
        //                         });

        //                         setIsSubmitting(false); // Re-enable the submit button
        //                     }

        //                 } else {

        //                     toast.error("Oops! Something went wrong. Please Contact TCMS Team", {
        //                         autoClose: 3000, // 3 seconds in this example
        //                     });

        //                     setIsSubmitting(false); // Re-enable the submit button

        //                 }
        //             } catch (error) {
        //                 console.error("Error fetching data:", error);
        //                 toast.error("Oops! Something went wrong. Please Contact TCMS Team", {
        //                     autoClose: 3000, // 3 seconds in this example
        //                 });

        //                 setIsSubmitting(false); // Re-enable the submit button

        //             } finally {
        //                 setIsSubmitting(false); // Re-enable the submit button

        //             }


        //         } else {

        //         }

        //         // You can also perform further actions based on the API response
        //         // For example, show a success message or redirect to another page.
        //     } catch (error) {
        //         // Handle any API request errors here
        //         toast.error("Oops! Something went wrong. Please Contact TCMS Team", {
        //             autoClose: 3000, // 3 seconds in this example
        //         });

        //         setIsSubmitting(false); // Re-enable the submit button


        //     }



        // }
        // else {


        // if (!profileimageUrl && formValues.cent_profile_pic!=null && ) {

        //     try {
        //         // Create a FormData object to send the form data, including the image file


        //         const formDataprof = new FormData();
        //         formDataprof.append("mode", "file_s3_upload_image");
        //         formDataprof.append("image", formValues.cent_profile_pic); // Append the image file
        //         const response_profile = await axios.post(BASE_URL + 'API/upload/index.php', formDataprof);
        //         const profiledata = '';
        //         // Handle the API response here
        //         if (response_profile.data.success === true) {
        //             profiledata = response_profile.data.data;
        //         } 
        //             try {
        //                 const response = await axios.post(BASE_URL + "API/api_index.php", {
        //                     security_key: security_key,
        //                     mode: "updateCenterDetails",
        //                     cent_profile: profiledata,
        //                     fk_ci_id: sessionStorage.getItem('user_id'),
        //                     user_type: sessionStorage.getItem('user_type'),
        //                     ...formValues,
        //                 });


        //                 if (response.data.success === true) {
        //                     if (response.data.data === 1) {
        //                         //resetFormFields(resetForm, setValues, initialValues);
        //                         toast.success("Success! Your form was submitted", {
        //                             autoClose: 3000, // 3 seconds in this example
        //                         });
        //                         if (formValues.cent_country != null && formValues.cent_country != '' && formValues.cent_country == '1') {
        //                             sessionStorage.setItem('country_alph_code', 'SG');
        //                             sessionStorage.setItem('country_code', formValues.cent_country);
        //                             sessionStorage.setItem('currency_code', 'SGD');
        //                         } else if (formValues.cent_country != null && formValues.cent_country != '' && formValues.cent_country == '183') {
        //                             sessionStorage.setItem('country_alph_code', 'GB');
        //                             sessionStorage.setItem('country_code', formValues.cent_country);
        //                             sessionStorage.setItem('currency_code', 'GBP');
        //                         } else if (formValues.cent_country != null && formValues.cent_country != '' && formValues.cent_country == '184') {
        //                             sessionStorage.setItem('country_alph_code', 'NZ');
        //                             sessionStorage.setItem('country_code', formValues.cent_country);
        //                             sessionStorage.setItem('currency_code', 'NZD');
        //                         } else if (formValues.cent_country != null && formValues.cent_country != '' && formValues.cent_country == '185') {
        //                             sessionStorage.setItem('country_alph_code', 'AU');
        //                             sessionStorage.setItem('country_code', formValues.cent_country);
        //                             sessionStorage.setItem('currency_code', 'AUD');
        //                         } else {
        //                             sessionStorage.setItem('country_alph_code', 'SG');
        //                             sessionStorage.setItem('country_code', '1');
        //                             sessionStorage.setItem('currency_code', 'SGD');
        //                         }
        //                         // After 3 seconds, redirect to another page
        //                         setTimeout(function () {
        //                             navigate("/add-centre-info");
        //                         }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds

        //                         setIsSubmitting(false); // Re-enable the submit button
        //                     } else {
        //                         toast.error("Failure! Unable to update centre. Try again later", {
        //                             autoClose: 3000, // 3 seconds in this example
        //                         });

        //                         setIsSubmitting(false); // Re-enable the submit button
        //                     }

        //                 } else {

        //                     toast.error("Failure! Unable to update centre. Try again later", {
        //                         autoClose: 3000, // 3 seconds in this example
        //                     });

        //                     setIsSubmitting(false); // Re-enable the submit button

        //                 }
        //             } catch (error) {
        //                 console.error("Error fetching data:", error);
        //                 toast.error("Failure! Unable to update centre. Try again later", {
        //                     autoClose: 3000, // 3 seconds in this example
        //                 });

        //                 setIsSubmitting(false); // Re-enable the submit button

        //             } finally {
        //                 setIsSubmitting(false); // Re-enable the submit button

        //             }

                

        //         // You can also perform further actions based on the API response
        //         // For example, show a success message or redirect to another page.
        //     } catch (error) {
        //         // Handle any API request errors here
        //         toast.error("Failure! Unable to update centre. Try again later", {
        //             autoClose: 3000, // 3 seconds in this example
        //         });

        //         setIsSubmitting(false); // Re-enable the submit button


        //     }

        //     // }


        // }
        // else {

            try {
                const response = await axios.post(BASE_URL + "API/api_index.php", {
                    security_key: security_key,
                    mode: "updateCenterDetails",
                    fk_ci_id: sessionStorage.getItem('user_id'),
                    user_type: sessionStorage.getItem('user_type'),
                    cent_profile: formValues.cent_profile_pic,
                    ...formValues,
                });


                if (response.data.success === true) {
                    if (response.data.data === 1) {
                        //resetFormFields(resetForm, setValues, initialValues);
                        toast.success("Success! Your form was submitted", {
                            autoClose: 3000, // 3 seconds in this example
                        });
                        if (formValues.cent_country != null && formValues.cent_country != '' && formValues.cent_country == '1') {
                            sessionStorage.setItem('country_alph_code', 'SG');
                            sessionStorage.setItem('country_code', formValues.cent_country);
                            sessionStorage.setItem('currency_code', 'SGD');
                        } else if (formValues.cent_country != null && formValues.cent_country != '' && formValues.cent_country == '183') {
                            sessionStorage.setItem('country_alph_code', 'GB');
                            sessionStorage.setItem('country_code', formValues.cent_country);
                            sessionStorage.setItem('currency_code', 'GBP');
                        } else if (formValues.cent_country != null && formValues.cent_country != '' && formValues.cent_country == '184') {
                            sessionStorage.setItem('country_alph_code', 'NZ');
                            sessionStorage.setItem('country_code', formValues.cent_country);
                            sessionStorage.setItem('currency_code', 'NZD');
                        } else if (formValues.cent_country != null && formValues.cent_country != '' && formValues.cent_country == '185') {
                            sessionStorage.setItem('country_alph_code', 'AU');
                            sessionStorage.setItem('country_code', formValues.cent_country);
                            sessionStorage.setItem('currency_code', 'AUD');
                        } else {
                            sessionStorage.setItem('country_alph_code', 'SG');
                            sessionStorage.setItem('country_code', '1');
                            sessionStorage.setItem('currency_code', 'SGD');
                        }
                        // After 3 seconds, redirect to another page
                        setTimeout(function () {
                            window.location.reload(false);
                        }, 2000); // 3000 milliseconds, which is equivalent to 3 seconds

                        setIsSubmitting(false); // Re-enable the submit button
                    }else if (response.data.data === 4) {
                        toast.error("Email already exists", {
                            autoClose: 3000, // 3 seconds in this example
                        });
                        setIsSubmitting(false);
                    } else {
                        toast.error("Failure! Unable to update centre. Try again later", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        setIsSubmitting(false); // Re-enable the submit button
                    }

                } else {

                    toast.error("Failure! Unable to update centre. Try again later", {
                        autoClose: 3000, // 3 seconds in this example
                    });

                    setIsSubmitting(false); // Re-enable the submit button

                }
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error("Failure! Unable to update centre. Try again later", {
                    autoClose: 3000, // 3 seconds in this example
                });

                setIsSubmitting(false); // Re-enable the submit button

            } finally {
                setIsSubmitting(false); // Re-enable the submit button

            }

       // }
    };
    // });

    const formik =  useFormik({
        initialValues: {
            /*tbl_certre*/
            cent_timezone: CentreData ? CentreData.cent_timezone : '',
            cent_timezone_identifier: CentreData ? CentreData.cent_timezone_identifier : '',
            cent_country: CentreData ? CentreData.cent_country : '',
            cent_currency: CentreData ? CentreData.cent_country : '',
            cent_dayofweek: CentreData ? CentreData.cent_weekday : '',

            cent_address: CentreData ? CentreData.cent_address : '',
            cent_emailid: CentreData ? CentreData.cent_emailid : '',
            cent_busvat: CentreData ? CentreData.cent_VAT : '',
            cent_website: CentreData ? CentreData.cent_website : '',
            cent_esign: CentreData ? CentreData.cent_email_sig : '',
            cent_elesign: CentreData ? CentreData.cent_elec_sig : '',
            cent_profile_pic: CentreData ? CentreData.cent_profile : '',
            cent_location: CentreData ? CentreData.cent_location : '',
            cent_city: CentreData ? CentreData.cent_city : '',
            cent_state: CentreData ? CentreData.cent_state : '',
            cent_zip: CentreData ? CentreData.cent_zip : '',
            cent_otp: CentreData ? CentreData.cent_otp : '',
            cent_smtp_username: CentreData ? CentreData.cent_smtp_username : '',
            cent_smtp_password: CentreData ? CentreData.cent_smtp_password : '',
            cent_smtp_host: CentreData ? CentreData.cent_smtp_host : '',
            cent_smtp_secure: CentreData ? CentreData.cent_smtp_secure : '',
            cent_smtp_port: CentreData ? CentreData.cent_smtp_port : '',
            cent_smtp_is_active: CentreData ? CentreData.cent_smtp_is_active : '',
            cent_smtp_message: CentreData ? CentreData.cent_smtp_message : '',
            cw_number_countryCode:'',
            cent_incharge: sessionStorage.getItem('first_name') + ' ' + sessionStorage.getItem('sur_name'),
            //cw_number: CentreData ? CentreData.cw_number : '',
            cw_number: CentreData ? CentreData.cw_number != '' && CentreData.cw_number != null ? CentreData.cw_number : '' : '',
            fk_ci_id: sessionStorage.getItem('user_id'),

            /*Common*/

            updated_by: sessionStorage.getItem('key'),
            fk_cent_key: sessionStorage.getItem('key')

        },
        enableReinitialize: true,
        validationSchema: basicSchema,
        onSubmit
    });

    //  const { values, handleBlur, touched, handleChange, errors, handleSubmit, setTouched, resetForm, setValues } = useFormik({
    // const formik = useFormik({

// Now, you can set variables as needed:
const values = formik.values;
const handleBlur = formik.handleBlur;
const touched = formik.touched;
const handleChange = formik.handleChange;
const errors = formik.errors;
const handleSubmit = formik.handleSubmit;
const setTouched = formik.setTouched;
const resetForm = formik.resetForm;
const setValues = formik.setValues;
const handleReset = formik.handleReset;
const fileInputRef = useRef(null);

    

    function handelRemove(event) {
        event.preventDefault();
        setImageUrl('');
    }
    function handelRemove1(event) {
        event.preventDefault();
        setImageUrlelecsig('');
    }
    function handelRemove2(event) {
        event.preventDefault();
        setProfileImageUrl('');
        handleChange({ target: { name: "cent_profile_pic", value: '' } }); // Clear the input value
    }


    const [smtpPasswordVisible, setsmtpPasswordVisible] = useState(false);

    const toggleSmtpPasswordVisibility = () => {
        setsmtpPasswordVisible(!smtpPasswordVisible);
    };

    useEffect(() => {
        if (!formik.isSubmitting) return;
        if (Object.keys(formik.errors).length > 0) { const firstErrorField = Object.keys(formik.errors)[0];
            //const firstErrorFieldId = `${firstErrorField}Id`;
          
            const errorFieldElement = document.getElementById(firstErrorField);
          
            if (errorFieldElement) {
               errorFieldElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
            } else {
             // console.error(`Element with ID ${firstErrorField} not found in the DOM.`);
            }
         //document.getElementById(Object.keys(formik.errors)[0]).focus();
        }
      }, [formik]);

    return (
        <>
             <ToastContainer position="top-center" />
            {/* center form */}
            <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
                <div className="">
                    <div className="mb-3 d-flex justify-content-between align-items-center">
                        <div>
                            <h5 className='page-title'> Centre Info </h5>
                            <div role="presentation" >
                                <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
                                    <NavLink underline="hover" color="inherit" to='/calendar'> Home</NavLink>


                                    <Typography color="text.primary">Centre</Typography>
                                </Breadcrumbs>
                            </div>
                        </div>
                        {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}

                    </div>

                    {/* --------------Student Particulars--------------- */}
                    <Stack>
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>Centre Information</h6>
                            </div>
                            <Row>



                                {/* <Col lg="4" md="6" sm="12" className='position-relative'>                                <h6 className="form-lable required-field">Phone</h6>
                                <Form.Control
                                    type="text"
                                    id="cent_phone"
                                    
                                    value={values.cent_phone}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={errors.cent_phone && touched.cent_phone ? "input-error" : ""}
                                />
                                {errors.cent_phone && touched.cent_phone && <p className="error">{errors.cent_phone}</p>}
                            </Col> */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>                                <h6 className="form-lable required-field">Email</h6>
                                    <Form.Control
                                        type="text"
                                        id="cent_emailid"
                                        name="cent_emailid"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.cent_emailid}

                                        className={errors.cent_emailid && touched.cent_emailid ? "input-error" : ""}
                                    />
                                    {errors.cent_emailid && touched.cent_emailid && <p className="error">{errors.cent_emailid}</p>}
                                </Col>

                                <Col lg="4" md="6" sm="12" className='position-relative'>                                <h6 className="form-lable required-field">BUSINESS / VAT NO.</h6>
                                    <Form.Control
                                        type="number"
                                        id="cent_busvat"

                                        value={values.cent_busvat}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={errors.cent_busvat && touched.cent_busvat ? "input-error" : ""}
                                    />
                                    {errors.cent_busvat && touched.cent_busvat && <p className="error">{errors.cent_busvat}</p>}


                                </Col>

                                <Col lg="4" md="6" sm="12" className='position-relative'>                                <h6 className="form-lable ">Website URL</h6>
                                    <Form.Control type="text"
                                        name="cent_website"
                                        id="cent_website"
                                        value={values.cent_website}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={errors.cent_website && touched.cent_website ? "input-error" : ""}

                                    />
                                    {errors.cent_website && touched.cent_website && <p className="error">{errors.cent_website}</p>}

                                </Col>


                                <Col lg="4" md="6" sm="12" className='position-relative' style={{ display: 'none' }}>                                <h6 className="form-lable required-field">Email Signature</h6>
                                    {!imageUrl && (
                                        <Form.Control
                                            type="file"
                                            name="cent_esign"
                                            id="cent_esign"

                                            onChange={(event) => {
                                                setTouched({ ...touched, cent_esign: true });
                                                if (event.target.files.length > 0) {
                                                    const fileName = event.target.files[0];
                                                    handleChange({ target: { name: "cent_esign", value: fileName } });
                                                } else {
                                                    // handleChange(event);
                                                    handleChange({ target: { name: "cent_esign", value: null } }); // Clear the input value
                                                }
                                            }}

                                            className={errors.cent_esign && touched.cent_esign ? "input-error" : ""}
                                        />

                                    )}
                                    {imageUrl && (
                                        <Col lg="4" md="6" sm="12" className='d-flex justify-content-between'>
                                            <img src={imageUrl} alt='image' width="50" height="50" />
                                            <div className="text-end">
                                                <Button variant="contained" className="primary-filled  btn btn-contained" onClick={handelRemove}>X</Button>
                                            </div>
                                        </Col>
                                    )}
                                    {errors.cent_esign && touched.cent_esign && <p className="error">{errors.cent_esign}</p>}


                                </Col>

                                <Col lg="4" md="6" sm="12" className='position-relative' style={{ display: 'none' }}>                                <h6 className="form-lable required-field">Electronic Signature</h6>
                                    {!imageUrlelecsig && (
                                        <Form.Control
                                            type="file"
                                            name="cent_elesign"
                                            id="cent_elesign"

                                            onChange={(event) => {
                                                setTouched({ ...touched, cent_elesign: true });
                                                if (event.target.files.length > 0) {
                                                    const fileName = event.target.files[0];
                                                    handleChange({ target: { name: "cent_elesign", value: fileName } });
                                                } else {
                                                    // handleChange(event);
                                                    handleChange({ target: { name: "cent_elesign", value: null } }); // Clear the input value
                                                }
                                            }}

                                            className={errors.cent_elesign && touched.cent_elesign ? "input-error" : ""}
                                        />
                                    )}
                                    {imageUrlelecsig && (
                                        <Col lg="4" md="6" sm="12" className='d-flex justify-content-between'>
                                            <img src={imageUrlelecsig} alt='image' width="50" height="50" />
                                            <div className="text-end">
                                                <Button variant="contained" className="primary-filled  btn btn-contained" onClick={handelRemove1}>X</Button>
                                            </div>
                                        </Col>
                                    )}
                                    {errors.cent_elesign && touched.cent_elesign && <p className="error">{errors.cent_elesign}</p>}
                                </Col>


                                <Col lg="4" md="6" sm="12" className='position-relative' style={{ display: 'none' }}>                                <h6 className="form-lable required-field">Location</h6>
                                    <Form.Control type="text"
                                        id="cent_location"

                                        value={values.cent_location}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={errors.cent_location && touched.cent_location ? "input-error" : ""}
                                    />
                                    {errors.cent_location && touched.cent_location && <p className="error">{errors.cent_location}</p>}

                                </Col>

                                <Col lg="4" md="6" sm="12" className='position-relative' style={{ display: 'none' }}>
                                    <h6 className="form-lable required-field"> Days of the Week </h6>

                                    <Select menuPlacement="auto" options={options_weeklydays}
                                        name="cent_dayofweek"
                                        value={options_weeklydays.find(option => option.value && values.cent_dayofweek && option.value.toString() === values.cent_dayofweek.toString())}
                                        id="cent_dayofweek"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "cent_dayofweek",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, cent_dayofweek: true });
                                        }}
                                        className={errors.cent_dayofweek && touched.cent_dayofweek ? "input-error" : ""}
                                    />
                                    {errors.cent_dayofweek && touched.cent_dayofweek && <p className="error">{errors.cent_dayofweek}</p>}


                                </Col>

                            </Row>

                        </div>
                    </Stack>
                    <Stack className='mt-4'>
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>Profile Details</h6>
                            </div>
                            <Row>


                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable "> Manage Login</h6>
                                    <Form.Control
                                        type={passwordVisible ? "text" : "password"}

                                        name="cent_password"
                                        id="cent_password"
                                        value={values.cent_password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        autoComplete="new-password"
                                        className={errors.cent_password && touched.cent_password ? "input-error" : ""}

                                    />
                                    <button
                                        type="button"
                                        onClick={togglePasswordVisibility}
                                        className="password-toggle-button pswicon" title="show / hide"
                                    >
                                        {passwordVisible ? <FiEye /> : <FiEyeOff />}

                                    </button>
                                    {errors.cent_password && touched.cent_password && <p className="error">{errors.cent_password}</p>}

                                </Col>


                                <Col lg="4" md="6" sm="12" className='position-relative' >
                                    <h6 className="form-lable ">Centre logo </h6>
                                    {!profileimageUrl && (
                                        <Form.Control
                                            type="file"
                                            name="cent_profile_pic"
                                            id="cent_profile_pic"

                                            accept=".png, .jpeg, .jpg, .jfif"
                                        onChange={(event) => {
                                            setTouched({ ...touched, cent_profile_pic: true });
                                            if (event.target.files.length > 0) {
                                                const fileName = event.target.files[0];
                                                async function handleFileUpload(fileName) {
                                                    try {
                                                        // Create a FormData object to send the form data, including the image file
                                                        const formData = new FormData();
                                                        formData.append("mode", "file_s3_upload_image");
                                                        formData.append("user", "centre");
                                                        formData.append("image", fileName); // Append the image file

                                                        // Make an API request here with the FormData object
                                                        const response_file = await axios.post(BASE_URL + 'API/upload/index.php', formData);

                                                        // Handle the API response here
                                                        if (response_file.data.success === true) {
                                                            handleChange({ target: { name: "cent_profile_pic", value: response_file.data.data } });
                                                        } else {
                                                            handleChange({ target: { name: "cent_profile_pic", value: '' } }); // Clear the input value
                                                            fileInputRef.current.value = null;
                                                        }

                                                        // You can also perform further actions based on the API response
                                                        // For example, show a success message or redirect to another page.
                                                    } catch (error) {
                                                        handleChange({ target: { name: "cent_profile_pic", value: '' } }); // Clear the input value
                                                        fileInputRef.current.value = null;
                                                    }
                                                }

                                                // Call the async function when needed, passing the fileName as an argument
                                                handleFileUpload(fileName);


                                            } else {
                                                // handleChange(event);
                                                handleChange({ target: { name: "cent_profile_pic", value: '' } }); // Clear the input value
                                                fileInputRef.current.value = null;
                                            }
                                        }}

                                        ref={fileInputRef}
                                            
                                            className={errors.cent_profile_pic && touched.cent_profile_pic ? "input-error" : ""}
                                        />

                                    )}
                                    {profileimageUrl && (
                                        <Col lg="4" md="6" sm="12" className='d-flex justify-content-between'>
                                            <img src={profileimageUrl} alt='image' width="50" height="50" />
                                            <div className="text-end">
                                                <Button variant="contained" className="primary-filled  btn btn-contained" onClick={handelRemove2}>X</Button>
                                            </div>
                                        </Col>
                                    )}
                                    {errors.cent_profile_pic && touched.cent_profile_pic && <p className="error">{errors.cent_profile_pic}</p>}


                                </Col>



                            </Row>

                        </div>
                    </Stack>

                    <Stack className='mt-4'>
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>Contact Details</h6>
                            </div>
                            <Row>

                                {/* --------------Contact Address1--------------- */}
                                <Col lg="12" md="12" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Address</h6>
                                    <Form.Control
                                        as="textarea" rows={3}

                                        value={values.cent_address}
                                        id="cent_address"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={errors.cent_address && touched.cent_address ? "input-error control_textarea" : "control_textarea"}
                                    />
                                    {errors.cent_address && touched.cent_address && <p className="error">{errors.cent_address}</p>}

                                </Col>
                                {/* --------------Student city--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative mt-3'>
                                    <h6 className="form-lable required-field">City/Town</h6>
                                    <Form.Control type="text"
                                        value={values.cent_city}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="cent_city"
                                        className={errors.cent_city && touched.cent_city ? "input-error" : ""}
                                    />
                                    {errors.cent_city && touched.cent_city && <p className="error">{errors.cent_city}</p>}
                                </Col>

                                {/* --------------Student state--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative mt-3'>
                                    <h6 className="form-lable required-field">County/Region/State</h6>
                                    <Form.Control type="text"
                                        value={values.cent_state}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="cent_state"
                                        className={errors.cent_state && touched.cent_state ? "input-error" : ""}
                                    />
                                    {errors.cent_state && touched.cent_state && <p className="error">{errors.cent_state}</p>}
                                </Col>

                                {/* --------------Student Country--------------- */}


                                <Col lg="4" md="6" sm="12" className='position-relative mt-3'>
                                    <h6 className="form-lable required-field">Country</h6>
                                    <Select menuPlacement="auto" options={options_countries}
                                        name="cent_country"
                                        value={options_countries.find(option => option.value && values.cent_country && option.value.toString() === values.cent_country.toString())}
                                        id="cent_country"
                                        isDisabled={true}
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "cent_country",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, cent_country: true });
                                        }}
                                        className={errors.cent_country && touched.cent_country ? "input-error" : ""}
                                    />
                                    {errors.cent_country && touched.cent_country && <p className="error">{errors.cent_country}</p>}
                                </Col>

                                {/* --------------Student Currency Code--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-label">Currency Code</h6>
                                    <Form.Control
                                        type="text"
                                        value={
                                            values.cent_country == '1' ? 'SGD' :
                                                values.cent_country == '183' ? 'GBP' :
                                                    values.cent_country == '184' ? 'NZD' :
                                                        values.cent_country == '185' ? 'AUD' :
                                                            ''
                                        }
                                        disabled={true}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="cent_currency"
                                        className={errors.cent_currency && touched.cent_currency ? "input-error" : ""}
                                    />
                                    {errors.cent_currency && touched.cent_currency && <p className="error">{errors.cent_currency}</p>}
                                </Col>
                                {/* --------------Student Postal Code--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Postal Code</h6>
                                    <Form.Control type="text"
                                        value={values.cent_zip}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="cent_zip"
                                        className={errors.cent_zip && touched.cent_zip ? "input-error" : ""}
                                    />
                                    {errors.cent_zip && touched.cent_zip && <p className="error">{errors.cent_zip}</p>}
                                </Col>

                                {/* --------------Student Timezone--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Timezone</h6>
                                    <Select menuPlacement="auto" options={options_time_zone}
                                        name="cent_timezone"
                                        value={options_time_zone.find(option => option.value && values.cent_timezone && option.value.toString() === values.cent_timezone.toString())}
                                        id="cent_timezone"
                                        isDisabled={true}
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "cent_timezone",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, cent_timezone: true });
                                        }}
                                        className={errors.cent_timezone && touched.cent_timezone ? "input-error" : ""}
                                    />
                                    {errors.cent_timezone && touched.cent_timezone && <p className="error">{errors.cent_timezone}</p>}
                                </Col>

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Timezone Identifier</h6>
                                    <Select menuPlacement="auto" options={options_time_zone_identifier}
                                        name="cent_timezone_identifier"
                                        value={options_time_zone_identifier.find(option => option.value && values.cent_timezone_identifier && option.value.toString() === values.cent_timezone_identifier.toString())}
                                        id="cent_timezone_identifier"
                                        isDisabled={true}

                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "cent_timezone_identifier",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, cent_timezone_identifier: true });
                                        }}
                                        className={errors.cent_timezone_identifier && touched.cent_timezone_identifier ? "input-error" : ""}
                                    />
                                    {errors.cent_timezone_identifier && touched.cent_timezone_identifier && <span className="error">{errors.cent_timezone_identifier}</span>}
                                </Col>


                            </Row>
                        </div>
                    </Stack>


                    <Stack className='mt-4'>
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>WhatsApp Details</h6>
                            </div>
                            <Row>

                                <Col lg="4" md="6" sm="12" className='position-relative' style={{ display: 'none' }}>
                                    <h6 className="form-lable required-field">Centre Incharge</h6>
                                    <Form.Control type="text"
                                        value={values.cent_incharge}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        readOnly
                                        id="cent_incharge"
                                        className={errors.cent_incharge && touched.cent_incharge ? "input-error" : ""}
                                    />
                                    {errors.cent_incharge && touched.cent_incharge && <p className="error">{errors.cent_incharge}</p>}
                                </Col>



                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-label required-field">WhatsApp Number</h6>
                                    <PhoneInput
                                        name="cw_number"
                                         countryCodeEditable={false}
                                        onlyCountries={['sg','nz','gb','au']}
                                        country={sessionStorage.getItem('country_alph_code') != null && sessionStorage.getItem('country_alph_code') != '' ? sessionStorage.getItem('country_alph_code').toLowerCase() : 'sg'} // Set the default country (e.g., singapore)
                                        value={values.cw_number}
                                        onChange={(phone, country) => {
                                            const event = {
                                                target: {
                                                    name: "cw_number",
                                                    value: phone,
                                                },
                                            };
                                            const event1 = {
                                                target: {
                                                    name: "cw_number_countryCode",
                                                    value: country.dialCode,
                                                },
                                                };
                                            handleChange(event1);
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, cw_number: true });
                                        }}
                                        inputProps={{id: 'cw_number'}}
                                        id="cw_number"
                                        inputClass={errors.cw_number && touched.cw_number ? 'input-error form-control' : 'form-control'}
                                    />
                                    {errors.cw_number && touched.cw_number && <span className="error">{errors.cw_number}</span>}
                                </Col>



                            </Row>
                        </div>
                    </Stack>
                    <Stack className='mt-4'>
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>From Email Details {['top'].map((placement) => (
                        <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                            <Tooltip id={`tooltip-${placement}`} className="custom-tooltip">
                                <div style={{ textAlign: 'initial' }}>
                            <div>
                                <p><strong>From Email Username:</strong> Your email address or the username provided by your SMTP service.</p>
                               
                                <p>Example: <strong>yourname@gmail.com</strong> (for Gmail), <strong>yourname@yourdomain.com</strong>.</p>
                                
                            </div>
                            <div>
                                <p><strong>From Email Password:</strong> The password or app-specific password for your email account.</p>
                                <p>Example: Generated app password for Gmail, Yahoo, or Outlook.</p>
                               
                            </div>
                            <div>
                                <p><strong>From Email Host:</strong> The server address for your SMTP provider.</p>
                                
                                <p>Example: <strong>smtp.gmail.com</strong> (for Gmail), <strong>smtp.office365.com</strong> (for Outlook).</p>
                                
                            </div>
                            <div>
                                <p><strong>From Email Secure:</strong> The security protocol to use.</p>
                                
                                <p>Options:</p>
                                <ul>
                                    <li><strong>STARTTLS</strong> (Most commonly used for port <strong>587</strong>)</li>
                                    <li><strong>SSL/TLS</strong> (Used for port <strong>465</strong>)</li>
                                </ul>
                               
                            </div>
                            <div>
                                <p><strong>From Email Port:</strong> The port number for SMTP connections.</p>
                                
                                <p>Common Ports:</p>
                                <ul>
                                    <li><strong>587</strong> (STARTTLS - Recommended)</li>
                                    <li><strong>465</strong> (SSL)</li>
                                    <li><strong>25</strong> (Non-encrypted, often blocked by ISPs)</li>
                                </ul>
                                
                            </div>
                            </div>
                            </Tooltip>
                        }
                        >
                            <Button variant="secondary" className="no-bdr">
                                <i class="fa-solid fa-info-circle ml-2 mr-2 mt-1"></i>
                            </Button>
                        </OverlayTrigger>
                        
                    ))}</h6>
                            </div>
                            <Row>
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">From Email Username</h6>
                                    <Form.Control type="text"
                                        value={values.cent_smtp_username}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="cent_smtp_username"
                                        className={errors.cent_smtp_username && touched.cent_smtp_username ? "input-error" : ""}
                                    />
                                    {errors.cent_smtp_username && touched.cent_smtp_username && <p className="error">{errors.cent_smtp_username}</p>}
                                </Col>

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">From Email Password</h6>
                                    <Form.Control type={smtpPasswordVisible ? 'text' : 'password'}
                                        value={values.cent_smtp_password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="cent_smtp_password"
                                        className={errors.cent_smtp_password && touched.cent_smtp_password ? "input-error" : ""}
                                    />
                                    <button
                                        type="button"
                                        onClick={toggleSmtpPasswordVisibility}
                                        className="password-toggle-button pswicon" title="show / hide"
                                    >
                                        {smtpPasswordVisible ? <FiEye /> : <FiEyeOff />}

                                    </button>
                                    {errors.cent_smtp_password && touched.cent_smtp_password && <p className="error">{errors.cent_smtp_password}</p>}
                                </Col>
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">From Email Host</h6>
                                    <Form.Control type="text"
                                        value={values.cent_smtp_host}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="cent_smtp_host"
                                        className={errors.cent_smtp_host && touched.cent_smtp_host ? "input-error" : ""}
                                    />
                                    {errors.cent_smtp_host && touched.cent_smtp_host && <p className="error">{errors.cent_smtp_host}</p>}
                                </Col>
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">From Email Secure</h6>
                                    <Form.Control type="text"
                                        value={values.cent_smtp_secure}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="cent_smtp_secure"
                                        className={errors.cent_smtp_secure && touched.cent_smtp_secure ? "input-error" : ""}
                                    />
                                    {errors.cent_smtp_secure && touched.cent_smtp_secure && <p className="error">{errors.cent_smtp_secure}</p>}
                                </Col>
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">From Email Port</h6>
                                    <Form.Control type="text"
                                        value={values.cent_smtp_port}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="cent_smtp_port"
                                        className={errors.cent_smtp_port && touched.cent_smtp_port ? "input-error" : ""}
                                    />
                                    {errors.cent_smtp_port && touched.cent_smtp_port && <p className="error">{errors.cent_smtp_port}</p>}
                                </Col>

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Status</h6>
                                   <p className={values.cent_smtp_is_active && values.cent_smtp_is_active == 1 ? 'text-success mb-1' : 'text-danger mb-1'}> {values.cent_smtp_is_active && values.cent_smtp_is_active == 1 ? 'Active' : 'In-active'}</p>
                                   {values.cent_smtp_is_active && values.cent_smtp_is_active == 1 ? null : <p className="text-danger mb-1">{values.cent_smtp_message}</p>}
                                </Col>
                                        
                            </Row>
                        </div>
                    </Stack>
                    <Stack className='mt-4'>
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>OTP</h6>
                            </div>
                            <Row>
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">OTP</h6>
                                    <Form.Control type="text"
                                        value={values.cent_otp}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="cent_otp"
                                        className={errors.cent_otp && touched.cent_otp ? "input-error" : ""}
                                    />
                                    {errors.cent_otp && touched.cent_otp && <p className="error">{errors.cent_otp}</p>}
                                </Col>




                            </Row>
                        </div>
                    </Stack>

                    <Stack gap={2} className="mt-4">
                        <div className="text-end">
                            <Button type="submit" variant="contained" className="primary-filled " disabled={isSubmitting}>Update</Button>
                        </div>
                    </Stack>
                </div>
            </Form>
        </>
    );
}

export default CentreInfo;