import React, { useState, useCallback, useEffect } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { useLocation, NavLink, useNavigate } from 'react-router-dom';



function Report() {
    const navigate = useNavigate();
   // const navigate = useNavigate();
    const location = useLocation();
    const [activeKey, setActiveKey] = useState('first');

    const handleSelect = (selectedKey) => {
        setActiveKey(selectedKey);
        // You can perform additional actions when a Nav.Link is selected
      };
    

        useEffect(() => {

            const previousPagePathname = sessionStorage.getItem('previousPagePathname');

            if (previousPagePathname) {
               
            
                if (['/report-student-assessment','/report-student-coins','/report-student-login','/report-student-contact_list'].includes(previousPagePathname)) {
                  
                    setActiveKey('second');
                    sessionStorage.setItem('previousPagePathname', location.pathname);
                
                }else if(['/report-centre-birthday','/report-centre-missed_sessions','/report-centre-entrolment_statistics','/report-centre-student_teacher_ratio','/report-centre-unenrollment'].includes(previousPagePathname)){
                    setActiveKey('third');
                    sessionStorage.setItem('previousPagePathname', location.pathname);
                }else{
                   
                    setActiveKey('first');
                }

            } else {
                    console.log('No referrer information available.');
            }

        }, [location.pathname]);

  
 
    const changeContainer = useCallback((fileName) => {
        navigate(fileName);
    }, [navigate]);


    return (
        <Container  fluid className='mx-0 px-0'>

            {/* --------------Page Start--------------- */}

            {/* --------------Tabs Section-1 Start--------------- */}
            <Row>
                <Col xs="12">
                    <div className="mx-auto">

                        <Tab.Container defaultActiveKey="first"   activeKey={activeKey} onSelect={handleSelect}>

                            <Row className="justify-content-center">

                                {/* --------------Toggle Selector Start--------------- */}
                                <Col sm={12}  className='border-bottom mb-2'>
                                    <Nav variant="pills" className="text-darks gap-3 " >
                                        <Nav.Item className="report-nav-container">
                                            <Nav.Link eventKey="first" className="d-flex rounded-2 px-auto f-18">
                                                <i class="fa-solid fa-people-roof me-2 my-auto"></i>
                                                <p className="mb-0">Finance Reports</p>
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="report-nav-container">
                                            <Nav.Link eventKey="second" className="rounded-2 px-auto f-18">
                                                <i class="fa-solid fa-file-invoice-dollar me-2 my-auto"></i>
                                                Student Reports
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="report-nav-container">
                                            <Nav.Link eventKey="third" className="rounded-2 px-auto f-18">
                                                <i class="fa-solid fa-graduation-cap me-2 my-auto"></i>
                                                Centre Management Reports
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                {/* --------------Toggle Selector End--------------- */}

                                {/* --------------Toggle Content Start--------------- */}
                                <Col sm={12}>
                                    <Tab.Content>

                                        {/* --------------Toggle One Start--------------- */}
                                        <Tab.Pane eventKey="first">
                                            <Row>
                                     
                                                <Col md={12} lg={4} className="mt-3 cursor-pointer" onClick={() => changeContainer("/report-finance-daily_pg_payments")}>
                                                    <div className="card my-card reports-list">
                                                        <p className="d-flex justify-content-between f-16 mb-0 p-3 align-items-center">
                                                        Daily PG Payments
                                                            <span><i class="fa-solid fa-chevron-right my-auto"></i></span>
                                                        </p>
                                                    </div>
                                                </Col>
                                                <Col md={12} lg={4} className="mt-3 cursor-pointer" onClick={() => changeContainer("/report-finance-daily_merchant_statement")}>
                                                    <div className="card my-card reports-list">
                                                        <p className="d-flex justify-content-between f-16 mb-0 p-3 align-items-center">
                                                        Daily Merchant Statement
                                                            <span><i class="fa-solid fa-chevron-right my-auto"></i></span>
                                                        </p>
                                                    </div>
                                                </Col>
                                                <Col md={12} lg={4} className="mt-3 cursor-pointer" onClick={() => changeContainer("/report-finance-debtor")}>
                                                    <div className="card my-card reports-list">
                                                        <p className="d-flex justify-content-between f-16 mb-0 p-3 align-items-center">
                                                        Debtor 
                                                            <span><i class="fa-solid fa-chevron-right my-auto"></i></span>
                                                        </p>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md={12} lg={4} className="mt-3 cursor-pointer" onClick={() => changeContainer("/report-finance-deposits")}>
                                                    <div className="card my-card reports-list">
                                                        <p className="d-flex justify-content-between f-16 mb-0 p-3 align-items-center">
                                                        Deposits 
                                                            <span><i class="fa-solid fa-chevron-right my-auto"></i></span>
                                                        </p>
                                                    </div>
                                                </Col>
                                                <Col md={12} lg={4} className="mt-3 cursor-pointer" onClick={() => changeContainer("/report-finance-timesheet")}>
                                                    <div className="card my-card reports-list">
                                                        <p className="d-flex justify-content-between f-16 mb-0 p-3 align-items-center">
                                                        Timesheet 
                                                            <span><i class="fa-solid fa-chevron-right my-auto"></i></span>
                                                        </p>
                                                    </div>
                                                </Col>
                                                <Col md={12} lg={4} className="mt-3 cursor-pointer" onClick={() => changeContainer("/report-finance-monthly_revenue")}>
                                                    <div className="card my-card reports-list">
                                                        <p className="d-flex justify-content-between f-16 mb-0 p-3 align-items-center">
                                                        Monthly Revenue 
                                                            <span><i class="fa-solid fa-chevron-right my-auto"></i></span>
                                                        </p>
                                                    </div>
                                                </Col>
                                            </Row>

                                            
                                        </Tab.Pane>
                                        {/* --------------Toggle One End--------------- */}
                                        {/* --------------Toggle Two Start--------------- */}
                                        <Tab.Pane eventKey="second">
                                            <Row>
                                                <Col md={12} lg={4} className="mt-3">
                                                    <NavLink to='/report-student-assessment' className="text-decoration-none">
                                                        <div className="card my-card reports-list">
                                                            <p className="d-flex justify-content-between f-16 mb-0 p-3 align-items-center">
                                                            Assessment 
                                                                <span><i class="fa-solid fa-chevron-right my-auto"></i></span>
                                                            </p>
                                                        </div>
                                                    </NavLink>
                                                </Col>
                                                <Col md={12} lg={4} className="mt-3">
                                                    <NavLink to='/report-student-coins' className="text-decoration-none">
                                                        <div className="card my-card reports-list">
                                                            <p className="d-flex justify-content-between f-16 mb-0 p-3 align-items-center">
                                                            Coins 
                                                                <span><i class="fa-solid fa-chevron-right my-auto"></i></span>
                                                            </p>
                                                        </div>
                                                    </NavLink>
                                                </Col>
                                                <Col md={12} lg={4} className="mt-3">
                                                    <NavLink to='/report-student-login' className="text-decoration-none">
                                                        <div className="card my-card reports-list">
                                                            <p className="d-flex justify-content-between f-16 mb-0 p-3 align-items-center">
                                                            Login 
                                                                <span><i class="fa-solid fa-chevron-right my-auto"></i></span>
                                                            </p>
                                                        </div>
                                                    </NavLink>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md={12} lg={4} className="mt-3">
                                                    <NavLink to='/report-student-contact_list' className="text-decoration-none">
                                                        <div className="card my-card reports-list">
                                                            <p className="d-flex justify-content-between f-16 mb-0 p-3 align-items-center">
                                                            Contact List
                                                                <span><i class="fa-solid fa-chevron-right my-auto"></i></span>
                                                            </p>
                                                        </div>
                                                    </NavLink>
                                                </Col>
                                                {/* <Col md={12} lg={4} className="mt-3">
                                                    <NavLink to='/liststudents-mui' className="text-decoration-none">
                                                        <div className="card my-card reports-list">
                                                            <p className="d-flex justify-content-between f-16 mb-0 p-3 align-items-center">
                                                            Student List
                                                                <span><i class="fa-solid fa-chevron-right my-auto"></i></span>
                                                            </p>
                                                        </div>
                                                    </NavLink>
                                                </Col> */}
                                            </Row>

                                           
                                        </Tab.Pane>
                                        {/* --------------Toggle Two End--------------- */}

                                        {/* --------------Toggle Three Start--------------- */}
                                        <Tab.Pane eventKey="third">
                                            <Row>
                                                <Col md={12} lg={4} className="mt-3 cursor-pointer" onClick={() => changeContainer("/report-centre-birthday")}>
                                                    <div className="card my-card reports-list">
                                                        <p className="d-flex justify-content-between f-16 mb-0 p-3 align-items-center">
                                                        Birthday 
                                                            <span><i class="fa-solid fa-chevron-right my-auto"></i></span>
                                                        </p>
                                                    </div>
                                                </Col>
                                                <Col md={12} lg={4} className="mt-3 cursor-pointer" onClick={() => changeContainer("/report-centre-missed_sessions")}>
                                                    <div className="card my-card reports-list">
                                                        <p className="d-flex justify-content-between f-16 mb-0 p-3 align-items-center">
                                                        Missed Sessions 
                                                            <span><i class="fa-solid fa-chevron-right my-auto"></i></span>
                                                        </p>
                                                    </div>
                                                </Col>
                                                <Col md={12} lg={4} className="mt-3 cursor-pointer" onClick={() => changeContainer("/report-centre-entrolment_statistics")}>
                                                    <div className="card my-card reports-list">
                                                        <p className="d-flex justify-content-between f-16 mb-0 p-3 align-items-center">
                                                        Enrolment Statistics 
                                                            <span><i class="fa-solid fa-chevron-right my-auto"></i></span>
                                                        </p>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md={12} lg={4} className="mt-3 cursor-pointer" onClick={() => changeContainer("/report-centre-student_teacher_ratio")}>
                                                    <div className="card my-card reports-list">
                                                        <p className="d-flex justify-content-between f-16 mb-0 p-3 align-items-center">
                                                        Student-Teacher Ratio 
                                                            <span><i class="fa-solid fa-chevron-right my-auto"></i></span>
                                                        </p>
                                                    </div>
                                                </Col>
                                                <Col md={12} lg={4} className="mt-3 cursor-pointer" onClick={() => changeContainer("/report-centre-unenrollment")}>
                                                    <div className="card my-card reports-list">
                                                        <p className="d-flex justify-content-between f-16 mb-0 p-3 align-items-center">
                                                        Unenrolment
                                                            <span><i class="fa-solid fa-chevron-right my-auto"></i></span>
                                                        </p>
                                                    </div>
                                                </Col>
                                                <Col md={12} lg={4} className="mt-3 cursor-pointer" onClick={() => changeContainer("/report-monthly-scheduled-sessions")}>
                                                    <div className="card my-card reports-list">
                                                        <p className="d-flex justify-content-between f-16 mb-0 p-3 align-items-center">
                                                        Monthly Scheduled Sessions
                                                            <span><i class="fa-solid fa-chevron-right my-auto"></i></span>
                                                        </p>
                                                    </div>
                                                </Col>
                                              
                                            </Row>

                                           
                                        </Tab.Pane>
                                        {/* --------------Toggle Three End--------------- */}

                                    </Tab.Content>
                                </Col>
                                {/* --------------Toggle Selector End--------------- */}

                            </Row>
                        </Tab.Container>

                    </div>
                </Col>
            </Row>
            {/* --------------Tabs Section-1 End--------------- */}




            {/* --------------Page End--------------- */}

        </Container>
    );
}

export default Report;